<template>
  <div :class="{'border-b':border}" class="flex flex-col items-center my-1 border-gray-400 pb-8 mx-6">
    <div class="flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between py-4">
      <div class="mx-6 flex-1 mb-4">
        <h1 class="color font-semibold text-xl">
          {{ title }}
        </h1>
        <p class="text-gray-500 py-2 text-md">
          {{ p1 }}
        </p>
      </div>

      <div class="mx-6 flex-1">
        <h1 class="text-xl text-gray-600">
          Who needs it?
        </h1>
        <p class="text-gray-500 py-2 text-md">
          {{ p2 }}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
name: "SolutionSection",
  props:{
    title:String,
    p1:String,
    p2:String,
    border:Boolean
  }
}
</script>

<style scoped>
.color{
  color:#cb9e37;
}
.text-md{
  font-size: 14px;
  width: 93%;
}
.btn{
  border-color: #cda240;
  border-width: 1.5px;
}
.btn:hover{
  background: #e9b949;
}
</style>