<template>
  <div>
    <div class="">
      <ul class=" flex items-center">
        <li @click="selectTab(tab)" v-for="tab in tabs" :key="tab" :class="{ 'text-bony-900 bg': tab.isActive }" class="py-3 px-4 cursor-pointer text-center font-semibold text-bony-800 bg-bony-400 flex-1">
          <a :href="tab.href" >{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
name: "Tabs",
  data() {
    return {tabs: [] };
  },

  created() {

    this.tabs = this.$children;

  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name == selectedTab.name);
      });
    }
  }
}
</script>

<style scoped>
.bg{
  background: white !important;
}
</style>