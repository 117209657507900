
<script>
import Main from "@/components/Main";
import SmallCard from "@/components/SmallCard";
import CardHeader from "@/components/CardHeader";
import SolutionSection from "@/components/SolutionSection";

export default {
name: "OurSolutions",

  components:{
    Main,SmallCard,CardHeader,SolutionSection
  },
  data(){
  return{
    StaffingSolutions:[
      {
        title:"Temporary Staffing",
        p1:"Staffin g blue- and white-collar temporary workers, from one to 1,000+.",
        p2:"Any business that depends on temporary/contingent workers. Common areas/industries include customer service, industrial &manufacturing, retail, and warehousing."

      },
      {
        title:"Direct Hire",
        p1:"Finding, interviewing, hiring and onboarding mostly low-to-mid-level professional talent, who become your permanent employees.",
        p2:"Any business that cannot, or prefers not to,dedicate resources to securing a few or many permanent employees."

      },
      {
        title:"Search",
        p1:"Similar to Direct Hire but with a more dedicated,consultative relationship, designed to find you the best mid-to-high-level professionals.",
        p2:"Any business that does not have the expertise,network, and/or time to recruit and hire a fitting senior manager, director or executive."

      },
    ],
    EnterpriseSolutions:[
      {
        title:"Master Vendor Program",
        p1:"Partnering with you as your primary staffing vendor so that you get a custom, scalable and efficient requisition and placement process.",
        p2:"Any medium to large business with a contingent workforce that needs full administrative,supervisory and systems support."

      },
      {
        title:"Onsite Management",
        p1:"Establishing a team who becomes an extension of your company, at your company, and manages your entire contingent labor program.",
        p2:"Any medium to large business with a contingent workforce that needs an immersive, end-to-end workforce management solution."

      },
      {
        title:"Managed Service Provider",
        p1:"A high-touch, comprehensive program that combines traditional MSP services and our staffing expertise.",
        p2:"Any medium to large business with a contingent workforce that needs full administrative,supervisory and systems support and a VMS."

      },
      {
        title:"Recruitment Process Outsourcing",
        p1:"A custom RPO solution for high-volume, direct hire needs with a dedicated team approach.",
        p2:"Any business with an enterprise-level project that requires recruiting, vetting and hiring dozens to hundreds of white-collar employees."

      },
    ]
  }
  },
  render(){
    return(
        <Main
            // Header Title, Paragraph and Photo
            title="Our Solutions"
            photo={require('@/assets/imgs/solution.jpg')}
            p="Providing more solutions for your Recruitment needs">
          <div class="container w-full mx-auto bg-white rounded-xl shadow-lg pb-6">
            <CardHeader padding="6"  title="Staffing Solutions"/>

              {this.StaffingSolutions.map((i,index)=><SolutionSection key={i.title} title={i.title} p1={i.p1} p2={i.p2} border={index!==2}/>)}

            <CardHeader padding="2"  title="Enterprise Solutions"/>

              {this.EnterpriseSolutions.map((i,index)=><SolutionSection key={i.title} title={i.title} p1={i.p1} p2={i.p2} border={index!==3}/>)}


          </div>
        </Main>
    )
  }
}
</script>

<style scoped>

</style>