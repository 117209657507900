<template>
  <div class="flex flex-1 flex-col m-4 items-center bg-bony-400 rounded-lg shadow">
    <h2 class="text-center cardTitle font-bold text-bony-800 p-2">{{ title }}</h2>
    <p class="text-center text-white px-5">
      {{ text }}
    </p>
    <div @click="click(title)" class="flex items-center cursor-pointer">
    <span class="text-white opacity-80 py-3">{{ footer }}</span>
    <span class="ml-2 mt-1">
      <svg xmlns="http://www.w3.org/2000/svg" width="22.25" height="18" viewBox="0 0 26.25 20">
        <path id="icons8_arrow" d="M18.875,4a.625.625,0,0,0-.625.625V10.25H4.5A2.5,2.5,0,0,0,2,12.75v2.5a2.5,2.5,0,0,0,2.5,2.5H18.25v5.625a.625.625,0,0,0,1.067.442q.019-.019.037-.039L27.867,14.9l0,0,.039-.041a1.245,1.245,0,0,0,0-1.709l-.015-.017-.019-.017L19.324,4.19l0,0A.625.625,0,0,0,18.875,4Z" transform="translate(-2 -4)" fill="#513c06"/>
      </svg>
    </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallCard",
  props:{
    title:String,
    text:String,
    footer:String,
    click:Function
  }
}
</script>

<style scoped>
.cardTitle{
  font-size: 22px;
}
.bg-bony{
  background:#c99a2e ;
}
</style>