
<script>

import Main from '@/components/Main'
import CardHeader from '@/components/CardHeader'
import SmallCard from '@/components/SmallCard'

export default {
name: "EmployerHome",
  components:{
    Main,SmallCard,CardHeader
  },
  methods:{
    goTo(link){
      alert(link+' clicked')
    }
  },
  render(){
    return(

        <Main
            // Header Title, Paragraph and Photo
            title="Make your workforce the best it can be."
            photo={require('@/assets/imgs/e-home.jpg')}
            p="Go beyond goals. Exceed expectations. Outrival rivals.You can do it all. But you need more than an employment agency. You need the world's best talent network. Tap into the power of our people."
            btn="Get employees"
            btnClick={()=> {
              this.$router.push('/employer/get-employees');
              this.$store.updateCurrentPage()
            }}
        >

            <div class="container w-full mx-auto bg-white rounded-xl shadow-lg pb-6">

            <CardHeader
                padding="10"
                title="Let’s talk about the employees you need"
            />

            <p
                style="width:90%"
                class="px-8 pt-2 pb-0 text-gray-500"
            >
              How many do you need? What do you need them to do?
              When do you need them? Whether it's temporary or permanent employees,
              5 people or 5,000, we’ll come up with a plan—a plan just for you—that
              makes sense for your budget and can be scaled up or down at any point.
              Then we’ll dive into our enormous candidate database—the largest of any employment
              agency—to get you some great people right away
            </p>

            <CardHeader
                // CardHeader without a title to make <hr> line
                padding="6"
                title=""
            />

            <div class="flex flex-col lg:flex-row xl:flex-row items-center justify-around px-2">

              <SmallCard
                  // Small Card with title, text and footer
                  title="Our Approach"
                  text="Want to know how we find you the best people, anytime, anywhere?"
                  footer="This is how we do it"
                  click={this.goTo}
              />

              <SmallCard
                  // Small Card with title, text and footer
                  title="About Us"
                  text="We’d rather talk about you, but if you really want to know, we’ll tell you."
                  footer="Here's our story"
                  click={this.goTo}
              />

              <SmallCard
                  // Small Card with title, text and footer
                  title="Salary Guide"
                  text="Most people don’t work for free. So find out what to pay them this year."
                  footer="Get your copy"
                  click={this.goTo}
              />

            </div>


            <CardHeader v-show={false}
                padding="2"
                title="Trusted by the best"
            />


            {/* Logos of who Trust us */}

            <div class="hidden flex flex-col lg:flex-row overflow-auto xl:flex-row items-center justify-around mt-8">

              <img class="flex-1 w-28 mx-4 my-4" src={require('@/assets/imgs/logo.png')} alt="logo"/>
                <img class="flex-1 w-28 mx-4 my-4" src={require('@/assets/imgs/logo.png')} alt="logo"/>
                  <img class="flex-1 w-28 mx-4 my-4" src={require('@/assets/imgs/logo.png')} alt="logo"/>
                <img class="flex-1 w-28 mx-4 my-4" src={require('@/assets/imgs/logo.png')} alt="logo"/>
              <img class="flex-1 w-28 mx-4 my-4" src={require('@/assets/imgs/logo.png')} alt="logo"/>

            </div>

          </div>

        </Main>

    )
  }
}
</script>

<style scoped>

</style>