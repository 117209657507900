<!--<template>-->


<!--</template>-->
<script>

export default {
  name:"Map",
  props:{
    click:Function
  },
  components: {

  },
  data(){
    return{
      currentmarker:'',
      center :[
        {
          state:"Alaska",
          pos:{
            lat: 61,
            lng: -152
          },
          jobs:this.$store.jobs.filter(i=>i.place.includes("Alaska"))
        },
        {
          state:"Alabama",
          pos:{
            lat: 32,
            lng: -86
          },
          jobs:this.$store.jobs.filter(i=>i.place.includes("Alabama"))
        },
        {
          state:"Arkansas",
          pos:{
            lat: 34,
            lng: -92
          },
          jobs:this.$store.jobs.filter(i=>i.place.includes("Arkansas"))
        },
        {
          state:"Arizona",
          pos:{
            lat: 33,
            lng: -111
          },
          jobs:this.$store.jobs.filter(i=>i.place.includes("Arizona"))
        },
        {
          state:"California",
          pos:{
            lat: 36.1700,
            lng: -119.7462
          },
          jobs:this.$store.jobs.filter(i=>i.place.includes("California"))
        },
        {
          state:"Colorado",
          pos:{
            lat: 39.0646,
            lng: -105.3272
          },
          jobs:this.$store.jobs.filter(i=>i.place.includes("Colorado"))
        },
        {
          state:"Connecticut",
          pos:{
            lat: 41.5834,
            lng: -72.7622
          },
          jobs:this.$store.jobs.filter(i=>i.place.includes("Connecticut"))
        },{
          state:"New York",
          lat:42.1497,
          lng:-74.9384,
          jobs:this.$store.jobs.filter(i=>i.place.includes("New York"))
        },
        // {
        //   state:"Delaware",
        //   pos:{
        //     lat: 39.3498,
        //     lng: -75.5148
        //   }
        // },
        // {
        //   state:"Florida",
        // pos:{
        //   lat: 27.8333,
        //   lng: -81.7170
        // }
        // },
        // {
        //   state:"Georgia",
        // pos:{
        //   lat: 32.9866,
        //   lng: -83.6487
        // }
        // },
        // {
        //   state:"Hawaii",
        // pos:{
        //   lat: 21.1098,
        //   lng: -157.5311
        // }
        // },
        // {
        //   state:"Iowa",
        // pos:{
        //   lat: 42.0046,
        //   lng: -93.2140
        // }
        // },
        // {
        //   state:"Idaho",
        // pos:{
        //   lat: 44.2394,
        //   lng: -114.5103
        // }
        // },
        // {
        //   state:"Illinois",
        // pos:{
        //   lat: 40.3363,
        //   lng: -89.0022
        // }
        // },
        // {
        //   state:"Indiana",
        // pos:{
        //   lat: 39.8647,
        //   lng: -86.2604
        // }
        // },
        // {
        //   state:"Kansas",
        // pos:{
        //   lat: 38.5111,
        //   lng: -96.8005
        // }
        // },
        // {
        //   state:"Kentucky",
        // pos:{
        //   lat: 37.6690,
        //   lng: -84.6514
        // }
        // },
        // {
        //   state:"Louisiana",
        // pos:{
        //   lat: 31.1801,
        //   lng: -91.8749
        // }
        // },
        // {
        //   state:"Massachusetts",
        //   lat:42.2373,
        //   lng:-71.5314
        // },
        // {
        //   state:"Maryland",
        //   lat:39.0724,
        //   lng:-76.7902
        // },
        // {
        //   state:"Maine",
        //   lat:44.6074,
        //   lng:-69.3977
        // },
        // {
        //   state:"Michigan",
        //   lat:43.3504,
        //   lng:-84.5603
        // },
        // {
        //   state:"Minnesota",
        //   lat:45.7326,
        //   lng:-93.9196
        // },
        // {
        //   state:"Missouri",
        //   lat:38.4623,
        //   lng:-92.3020
        // },
        // {
        //   state:"Mississippi",
        //   lat:32.7673,
        //   lng:-89.6812
        // },
        // {
        //   state:"Montana",
        //   lat:46.9048,
        //   lng:-110.3261
        // },
        // {
        //   state:"North Carolina",
        //   lat:35.6411,
        //   lng:-79.8431
        // },
        // {
        //   state:"North Dakota",
        //   lat:47.5362,
        //   lng:-99.7930
        // },
        // {
        //   state:"Nebraska",
        //   lat:41.1289,
        //   lng:-98.2883
        // },
        // {
        //   state:"New Hampshire",
        //   lat:43.4108,
        //   lng:-71.5653
        // },
        // {
        //   state:"New Jersey",
        //   lat:40.3140,
        //   lng:-74.5089
        // },
        // {
        //   state:"New Mexico",
        //   lat:34.8375,
        //   lng:-106.2371
        // },
        // {
        //   state:"Nevada",
        //   lat:38.4199,
        //   lng:-117.1219
        // },

        // {
        //   state:"Ohio",
        //   lat:40.3736,
        //   lng:-82.7755
        // },
        // {
        //   state:"Oklahoma",
        //   lat:35.5376,
        //   lng:-96.9247
        // },
        // {
        //   state:"Oregon",
        //   lat:44.5672,
        //   lng:-122.1269
        // },
        // {
        //   state:"Pennsylvania",
        //   lat:40.5773,
        //   lng:-77.2640
        // },
        // {
        //   state:"Rhode Island",
        //   lat:41.6772,
        //   lng:-71.5101
        // },
        // {
        //   state:"South Carolina",
        //   lat:33.8191,
        //   lng:-80.9066
        // },
        // {
        //   state:"South Dakota",
        //   lat:44.2853,
        //   lng:-99.4632
        // },
        // {
        //   state:"Tennessee",
        //   lat:35.7449,
        //   lng:-86.7489
        // },
        // {
        //   state:"Texas",
        //   lat:31.1060,
        //   lng:-97.6475
        // },
        // {
        //   state:"Utah",
        //   lat:40.1135,
        //   lng:-111.8535
        // },
        // {
        //   state:"Virginia",
        //   lat:37.7680,
        //   lng:-78.2057
        // },
        // {
        //   state:"Vermont",
        //   lat:44.0407,
        //   lng:-72.7093
        // },
        // {
        //   state:"Washington",
        //   lat:47.3917,
        //   lng:-121.5708
        // },
        // {
        //   state:"Wisconsin",
        //   lat:44.2563,
        //   lng:-89.6385
        // },
        // {
        //   state:"West Virginia",
        //   lat:38.4680,
        //   lng:-80.9696
        // },
        // {
        //   state:"Wyoming",
        //   lat:42.7475,
        //   lng:-107.2085
        // }
      ]
    }
  },
  methods:{
    selectMarker(m){
      this.click(m)
      this.currentmarker=m
    }
  },
  render(){
    return(

        <googlemaps-map
            style="height: 400px"
            class="rounded-lg"
            center={{lat:38, lng:-97}}
            zoom={4}>
          {this.center.map(marker=>(
            marker.jobs.length&&<googlemaps-marker
                key={marker.state}
                label={{
                  color: marker.state === this.currentmarker ? '#C99A2E' : 'black',
                  fontFamily: 'Proxy',
                  fontSize: '12px',
                  className: 'mb-4 font-semibold text-bony-900  ',
                  text: String(marker.jobs.length),
                }}
                position={marker.pos}
                icon={require('../pages/Seeker/w.svg')}
                onClick={()=>this.selectMarker(marker.state)}
            />
            ))
          }
  </googlemaps-map>
    )
  }
}
</script>
