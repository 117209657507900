<template>
  <div>

  <SecondNavbar part="employer" :links="links"/>

    <OurSolutions v-if="$route.params.id==='solutions'"/>
    <Industry v-else-if="$route.params.id==='industries'"/>
    <FindEmployees v-else-if="$route.params.id==='get-employees'"/>
    <ContactUs v-else-if="$route.params.id==='contact-us'"/>
    <EmployerHome v-else/>

  </div>

</template>



<script>
  import SecondNavbar from "@/components/SecondNavbar";
  import EmployerHome from "@/pages/Employer/EmployerHome";
  import Industry from "@/pages/Industry";
  import OurSolutions from "@/pages/Employer/OurSolutions";
  import FindEmployees from "@/pages/Employer/FindEmployees";
  import ContactUs from "../ContactUs";

export default {
name: "Employer",
  components: {
    ContactUs,
    FindEmployees,
    OurSolutions,
    Industry,
    EmployerHome,
    SecondNavbar
  },
  data(){
  return{
    links:[
      {name:'Home',to:'home'},
      {name:'Our solutions',to:'solutions'},
      {name:'Industries',to:'industries'},
      {name:'Get employees',to:'get-employees'},
      {name:'Contact us',to:'contact-us'}
    ]
  }
  }
}
</script>

<style scoped>

</style>