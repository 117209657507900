
<template>
  <nav class="w-full bg">
    <div class="container px-8 lg:px-20 py-8 h-12 flex justify-center items-center lg:items-stretch mx-auto">

      <div class="flex items-center w-full justify-center">
        <ul class="hidden xl:flex flex-2 items-center h-full justify-center self-center">

          <li  v-for="link in links" :key="link.to" @click="routerHandler(link)" :class='`${($store.currentPage.includes(link.to)) ? `font-bold active` : `text-bony-800 text-opacity-40`}`' class="cursor-pointer h-16 px-4 flex items-center hover:text-opacity-100 text-sm tracking-normal">
              {{link.name}}
          </li>

        </ul>
      </div>


      <div class="visible xl:hidden flex items-center">

        <ul class="p-2 pb-4 border-r bg-white absolute top-32 left-0 right-0 shadow hidden">
          <li v-for="link in links" @click="routerHandler(link)" :key="link.to" :class="{'text-bony-800':$store.currentPage.includes(link.to)}" class="flex xl:hidden justify-center cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-bony-800 focus:text-bony-800 focus:outline-none">
              <h2 class="ml-2 font-bold">{{ link.name }}</h2>
          </li>
        </ul>


        <svg @click="MenuHandler($event, true)" aria-haspopup="true" aria-label="Main Menu" xmlns="http://www.w3.org/2000/svg" class="show-m-menu icon icon-tabler icon-tabler-menu" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z"></path>
          <line x1="4" y1="8" x2="20" y2="8"></line>
          <line x1="4" y1="16" x2="20" y2="16"></line>
        </svg>
        <div class="hidden close-m-menu close" @click="MenuHandler($event, false)">
          <svg aria-label="Close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SecondNavbar",
  props:{
    logo:String,
    name:String,
    links:Array,
    profile:Object,
    part:String
  },
  data() {
    return {
      profilePhoto: this.profile,
    };
  },

  mounted() {
  },
  methods: {
    dropdownHandler(event) {
      let single = event.currentTarget.getElementsByTagName("ul")[0];
      single.classList.toggle("hidden");
    },
    MenuHandler(el, val) {
      let MainList = el.currentTarget.parentElement.getElementsByTagName("ul")[0];
      let closeIcon = el.currentTarget.parentElement.getElementsByClassName("close-m-menu")[0];
      let showIcon = el.currentTarget.parentElement.getElementsByClassName("show-m-menu")[0];
      if (val) {
        MainList.classList.remove("hidden");
        el.currentTarget.classList.add("hidden");
        closeIcon.classList.remove("hidden");
      } else {
        showIcon.classList.remove("hidden");
        MainList.classList.add("hidden");
        el.currentTarget.classList.add("hidden");
      }
    },
    searchHandler(event) {
      let Input = event.currentTarget.parentElement.getElementsByTagName("input")[0];
      Input.classList.toggle("w-0");
      Input.classList.toggle("w-24");
    },
    routerHandler(link){
      if(link.to.includes('http')){
        open(link.to)
        return
      }
      this.$router.push(`/${this.part}/${link.to}`);
      this.$store.currentPage=window.location.pathname;
      document.querySelectorAll('.close').forEach(i=>i.click());
    }
  },
};
</script>



<style scoped lang="scss">
$bony:#f7d070;
$bony-dark:#513c06;
.flex-2{
  flex:2
}
.w-18{
  width:6rem
}
.bg{
  background: $bony;
}
.active{
  color:#7c5e10;
}
.sm-active{
  color:#7c5e10;
}
</style>