<template>
<div class="2xl:px-80 lg:px-36 w-full md:px-14 sm:px-4 px-4 mb-10">
  <div :style='`background: linear-gradient(to right, rgba(34,34,34,0.9), rgba(124,124,124,0.5)), url(${photo}); background-position:top;`' class="lg:px-20 px-10 pt-28 photo pb-24">
<!--    <img :src="photo" class="w-full" alt="photo"/>-->
    <h1 class="title">
      {{ title }}
    </h1>
    <p class="p">
      {{ p }}
    </p>
    <div class="flex">
    <button @click="btnClick()" style="width:9rem" v-if="btn" class="border-2 rounded-md border-bony-500  font-semibold py-1 hover:bg-transparent hover:text-bony-300 btn bg-bony-500 hover:border-white">{{ btn }}</button>
    <button @click="btn2Click()" style="width:9rem" v-if="btn2" class="border-2 hover:text-gray-900 ml-2 rounded-md border-bony-50 font-semibold py-1 text-bony-300 btn hover:bg-bony-500 hover:border-bony-500">{{ btn2 }}</button>

    </div>
  </div>
  <div class="lg:px-16 md:px-4 px-2 t5">
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
name: "Main",
  props:{
    photo:String,
    title:String,
    p:String,
    btn:String,
    btnClick:Function,
    btn2:String,
    btn2Click:Function
  }
}
</script>

<style lang="scss" scoped>
$title:#f9da8b;
.title{
  color:$title;
  font-size: 44px;
  font-weight: bolder;
  width: 67%;
}
.photo{
  background-size: cover !important;
}
.p{
  color:#fff;
  width:55%;
  opacity:0.65;
  font-size:17px
}
//.btn:hover{
//  color:#222
//}
@media(max-width:480px){
  .title{
    font-size:34px;
    width: 100%;
  }
  .p{
    width: 100%;
  }
}
@media(min-width:1800px){
  .title{
    color:$title;
    font-size: 44px;
    font-weight: bolder;
    width: 60%;
  }
  .p{
    color:#fff;
    width:30%;
    opacity:0.65;
    font-size:17px
  }
}
.t5{
  margin-top: -4rem;
}
.t7{
  margin-top:-8rem
}
</style>

<!--min-height: calc(70vh + ${btn||title.includes("help")?70:10}px);-->