
<script>

  import Main from "@/components/Main";
    import CardHeader from "@/components/CardHeader";
      import FormSection from "@/components/FormSection";
    import Input from "@/components/Input";
import axios from 'axios'

export default {
  name: "ContactUs",
  components:{
    Input,
    Main,CardHeader,FormSection
  },
  data(){
    return{
      form:{
        name:'',
        email:'',
        subject:'',
        massege:''
      },
    }
  },
  methods:{
    submitForm(){
      axios.post('contact_us.php', this.toFormData(this.form))
          .then( function(response) {
            console.log(response.data)
            if(response.data){
              document.querySelector('.bgac').style.display='block'
              document.querySelector('.thankc').style.display='flex'
              this.form={
                name:'',
                email:'',
                subject:'',
                massege:''
              }
            }
          });
    },
    toFormData: function(obj) {
      let formData = new FormData();
      for(let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    hideBg(){
      document.querySelector('.bgac').style.display='none'
      document.querySelector('.thankc').style.display='none'
    },
  },
  render(){
    return(

        <Main
             // Header Title, Paragraph and Photo
            title="Contact us"
            photo={require('@/assets/imgs/contact-us.jpg')}
            p="If you need to contact MetroJobs, we’re here to help."
        >

          <div class="container w-full mx-auto bg-white rounded-xl shadow-lg pb-2">
            <CardHeader padding="6"  title="Contact Us"/>

            <FormulateForm onSubmit={()=>this.submitForm()}>
              <FormSection title="Account info" border={true}>
              <div class="mb-2 grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

                <FormulateInput
                    type="text"
                    placeholder="Name"
                    name="name"
                    label="Name"
                    validation="required"
                    v-model={this.form.name}
                />
                <FormulateInput
                    type="email"
                    placeholder="example@gmail.com"
                    name="email"
                    label="Email Address"
                    validation="required|email"
                    v-model={this.form.email}
                />
              </div>

              </FormSection>


                  <CardHeader padding="0"/>


              <FormSection title="Massege" border={true}>


                <div class="mb-2 grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

                  <FormulateInput
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      label="Subject"
                      validation="required"
                      v-model={this.form.subject}
                  />

                </div>

                <h1 class="mb-1 mt-6 px-2 text-gray-600">Massege</h1>
                <FormulateInput
                    input-class={["h-32"]}
                    v-model={this.form.massege}
                    placeholder="write about the job"
                    type="textarea"
                />


              </FormSection>

                <CardHeader padding="0"/>

                <div class="flex justify-end px-5">
                  <FormulateInput
                      type="submit"
                      input-class="font-semibold bg-bony-400 text-bony-800 btn font-normal hover:text-white py-2.5 px-8 my-4 border hover:border-transparent rounded-lg"
                      value='Send'
                  />
                </div>

              <a id="mail" class="hidden" href={`mailto:info@metrojobsonline.com?subject="${this.form.subject}"&body="${this.form.massege}"`}/>
            </FormulateForm>


            <div class="bgac hidden" onClick={this.hideBg}></div>
            <div style="background:#EFFCF6;"
                 class="w-1/2 hidden fixed thankc animated bounceInUp top-1/4 left-1/4 flex items-center shadow-md rounded-lg mb-6 flex-col p-6">

              <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 125 125">
                <g id="Group_314" data-name="Group 314" transform="translate(27 8.116)">
                  <circle id="Ellipse_8" data-name="Ellipse 8" cx="62.5" cy="62.5" r="62.5"
                          transform="translate(-27 -8.116)" fill="#65d6ad" opacity="0.5"/>
                  <path id="icons8_ok"
                        d="M32.619,2A30.619,30.619,0,1,0,63.239,32.619,30.619,30.619,0,0,0,32.619,2ZM50.094,25.6,28.66,47.032a3.06,3.06,0,0,1-4.33,0l-9.186-9.186a3.061,3.061,0,0,1,4.33-4.33L26.5,40.537,45.764,21.269a3.061,3.061,0,0,1,4.33,4.33Z"
                        transform="translate(2.812 21.904)" fill="#0c6b58"/>
                </g>
              </svg>


              <h1 style="color:#147D64" class="text-xl text-center font-bold my-4">
                Thank you!
                <span class="text-sm text-black block font-normal">
                Your submission has been sent.
              </span>
              </h1>
            </div>

            </div>
        </Main>
    )
  }
}
</script>

<style lang="scss">
.select{
  background: #f3f4f6 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='55' height='24.911' viewBox='0 0 25 14.911'%3E%3Cpath id='icons8_expand_arrow' d='M18.869,19.11l-8.481-8.481a2.355,2.355,0,0,0-3.329,0h0a2.355,2.355,0,0,0,0,3.329L17.3,24.2a2.217,2.217,0,0,0,3.139,0L30.679,13.958a2.355,2.355,0,0,0,0-3.329h0a2.355,2.355,0,0,0-3.329,0Z' transform='translate(-6.369 -9.939)' fill='%237e7e7e'/%3E%3C/svg%3E%0A") no-repeat right .75rem center/13px 15px !important;
  appearance: none;
  padding: 0.660rem;
}
.date{
  background: #f3f4f6 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='27.778' viewBox='0 0 25 27.778'%3E%3Cpath id='icons8_calendar' d='M8.556,1A1.389,1.389,0,0,0,7.167,2.389V3.778H5.778A2.786,2.786,0,0,0,3,6.556V26a2.786,2.786,0,0,0,2.778,2.778H25.222A2.786,2.786,0,0,0,28,26V6.556a2.786,2.786,0,0,0-2.778-2.778H23.833V2.389a1.389,1.389,0,0,0-2.778,0V3.778H9.944V2.389A1.389,1.389,0,0,0,8.556,1ZM5.778,10.722H25.222V24.611A1.389,1.389,0,0,1,23.833,26H7.167a1.389,1.389,0,0,1-1.389-1.389Z' transform='translate(-3 -1)' fill='%237e7e7e'/%3E%3C/svg%3E%0A") no-repeat right .75rem center/13px 15px !important;
  appearance: none;
  padding: 8px 0.660rem;
}
.z-10{
  @apply text-bony-800
}
.number{
  background: #f3f4f6 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='41.911' viewBox='0 0 25 41.911'%3E%3Cg id='Group_195' data-name='Group 195' transform='translate(-1213 -374)'%3E%3Cpath id='icons8_expand_arrow' d='M18.869,19.11l-8.481-8.481a2.355,2.355,0,0,0-3.329,0h0a2.355,2.355,0,0,0,0,3.329L17.3,24.2a2.217,2.217,0,0,0,3.139,0L30.679,13.958a2.355,2.355,0,0,0,0-3.329h0a2.355,2.355,0,0,0-3.329,0Z' transform='translate(1206.632 391.06)' fill='%237e7e7e'/%3E%3Cpath id='icons8_expand_arrow-2' data-name='icons8_expand_arrow' d='M18.869,19.11l-8.481-8.481a2.355,2.355,0,0,0-3.329,0h0a2.355,2.355,0,0,0,0,3.329L17.3,24.2a2.217,2.217,0,0,0,3.139,0L30.679,13.958a2.355,2.355,0,0,0,0-3.329h0a2.355,2.355,0,0,0-3.329,0Z' transform='translate(1244.369 398.851) rotate(180)' fill='%237e7e7e'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat right 0.75rem center/13px 15px !important;
  appearance: textfield;
  //padding: 0.660rem;
}

input:checked + svg {
  display: block;
}

.bgac{
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left:0;
}
</style>


<!--<FormulateInput-->
<!--    name="toppings"-->
<!--    label="Temporary"-->
<!--    type="checkbox"-->
<!--    wrapper-class="flex items-center"-->
<!--    input-class="bg-gray-400 mt-2"-->
<!--/>-->

<!--<FormulateInput-->
<!--    name="toppings"-->
<!--    label="Temporary"-->
<!--    type="checkbox"-->
<!--    wrapper-class="flex items-center ml-4"-->
<!--    input-class="bg-gray-400 mt-2"-->
<!--/>-->