<template>
  <div class="flex flex-1 mx-1 flex-col mb-2">
    <lable class="px-2 text-gray-600">{{ lable }}</lable>
    <input type="text" :placeholder="placeholder" style="border-color: #e9b949" class="bg-gray-100 mt-2 rounded-md flex-1 p-2 border-b-2" />
  </div>
</template>

<script>
export default {
name: "Input",
  props:{
    placeholder:String,
    lable:String,
  }
}
</script>

<style scoped>

</style>