import Vue from 'vue'
import App from './App.vue'
import "tailwindcss/tailwind.css"
import './assets/css/style.css'
import './assets/css/animate.css'
import VueRouter from 'vue-router'
import VueFormulate from '@braid/vue-formulate'
import Employer from "@/pages/Employer/Employer";
import Seeker from "@/pages/Seeker/Seeker";
import 'vue-googlemaps/dist/vue-googlemaps.css'
import VueGoogleMaps from 'vue-googlemaps';
import CKEditor from '@ckeditor/ckeditor5-vue2';


Vue.use( CKEditor );
Vue.use(VueGoogleMaps, {
    load: {
        // Google API key
        apiKey: 'AIzaSyBZUC2w7IpIopTu-gvvMWiDbvKDq0Oho5c',
        // Enable more Google Maps libraries here
        libraries: ['places'],
        // Use new renderer
        useBetaRenderer: false,
    },
})

Vue.config.productionTip = false


Vue.use(VueRouter)
Vue.use(VueFormulate, {
  classes: {
    outer: 'mb-4',
    input: 'border-bony-500 bg-gray-100 mt-2 rounded-md flex-1 p-2 border-b-2 leading-none outline-none border-box w-full mb-1',
    label: 'px-2 text-gray-600',
    help: 'text-xs my-1 text-gray-600',
    error: 'text-red-700 text-xs my-1'
  }
})

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {path: '/employer/:id', component: Employer},
    {path: '/employer/', component: Employer},
    {path: '/index.html', component: Seeker},
    {path: '/', component: Seeker},
    {path: '/seeker/:id', component: Seeker},
    {path: '/seeker/', component: Seeker},
  ]
})


const store = Vue.observable({
  state:{

  },
  industries:[
    {
      value:'OCA',
      label:'Office, Clerical & Administrative',
      icon:`
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 100 100.804">
          <path id="icons8_admin_settings_male"
                d="M46.2,3A19.2,19.2,0,1,0,65.4,22.2,19.2,19.2,0,0,0,46.2,3Zm0,52.8C31.124,55.8,3,63.218,3,77.4v7.2a4.8,4.8,0,0,0,4.8,4.8H47.617a33.45,33.45,0,0,1,7.96-32.851A65.723,65.723,0,0,0,46.2,55.8Zm29.111,0A1.218,1.218,0,0,0,74.1,56.88l-.563,4.856a19.041,19.041,0,0,0-6.244,3.61L62.8,63.4a1.206,1.206,0,0,0-1.528.516l-4.5,7.781a1.218,1.218,0,0,0,.328,1.585l3.872,2.869A19.591,19.591,0,0,0,60.6,79.8a19.061,19.061,0,0,0,.366,3.647l-3.863,2.878a1.215,1.215,0,0,0-.328,1.585l4.491,7.781a1.214,1.214,0,0,0,1.537.506L67.3,94.26a19.077,19.077,0,0,0,6.244,3.61l.563,4.856a1.211,1.211,0,0,0,1.21,1.078h8.981a1.218,1.218,0,0,0,1.21-1.078l.563-4.856a19.041,19.041,0,0,0,6.244-3.61l4.5,1.95a1.211,1.211,0,0,0,1.528-.516l4.5-7.781a1.218,1.218,0,0,0-.328-1.585L98.638,83.45a18.366,18.366,0,0,0,0-7.294l3.863-2.878a1.215,1.215,0,0,0,.328-1.585l-4.491-7.781a1.214,1.214,0,0,0-1.537-.506L92.31,65.346a19.077,19.077,0,0,0-6.244-3.61L85.5,56.88a1.211,1.211,0,0,0-1.21-1.078ZM79.8,71.4a8.4,8.4,0,1,1-8.4,8.4A8.4,8.4,0,0,1,79.8,71.4Z"
                transform="translate(-3 -3)" fill="#f7d070"/>
        </svg>
        `
    },
    {
      value:'HR',
      label:'Human Resources',
      icon:`
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="48.333" viewBox="0 0 100 58.333">
          <path id="icons8_management" d="M20.833,5a12.366,12.366,0,0,0-12.5,12.5A12.366,12.366,0,0,0,20.833,30a12.366,12.366,0,0,0,12.5-12.5A12.366,12.366,0,0,0,20.833,5ZM50,5A12.366,12.366,0,0,0,37.5,17.5a12.5,12.5,0,0,0,25,0A12.366,12.366,0,0,0,50,5ZM79.167,5a12.366,12.366,0,0,0-12.5,12.5,12.5,12.5,0,1,0,25,0A12.366,12.366,0,0,0,79.167,5ZM50,38.333c-14.721,0-25,5.515-25,13.412v7.422a4.168,4.168,0,0,0,4.167,4.167H70.833A4.168,4.168,0,0,0,75,59.167V51.745C75,43.849,64.721,38.333,50,38.333Zm27.2.049a17.7,17.7,0,0,1,6.136,13.363v7.422A4.168,4.168,0,0,0,87.5,63.333h8.333A4.168,4.168,0,0,0,100,59.167V51.745C100,44.249,90.726,38.907,77.2,38.382ZM22.8,38.39C9.274,38.911,0,44.249,0,51.745v7.422a4.168,4.168,0,0,0,4.167,4.167H12.5a4.168,4.168,0,0,0,4.167-4.167V51.745A17.686,17.686,0,0,1,22.8,38.39Z" transform="translate(0 -5)" fill="#f7d070"/>
        </svg>
        `
    },
    {
      value:'CS',
      label:'Call Center & Customer Service',
      icon:`
        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="58" viewBox="0 0 100 90">
          <path id="icons8_headset_1" d="M52,3A40.076,40.076,0,0,0,12,43v5A10.029,10.029,0,0,0,2,58V68A10.029,10.029,0,0,0,12,78H22V43a30,30,0,0,1,60,0V83H61.561a7.494,7.494,0,1,0-7.6,9.971A5.007,5.007,0,0,0,54.5,93H82A10.074,10.074,0,0,0,92,83V78a10.029,10.029,0,0,0,10-10V58A10.029,10.029,0,0,0,92,48V43A40.076,40.076,0,0,0,52,3Z" transform="translate(-2 -3)" fill="#f7d070"/>
        </svg>
        `
    },


    {
      value:'AF',
      label:'Accounting & Finance',
      icon:`
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100">
            <path id="icons8_account" d="M29.7,3a4.442,4.442,0,0,0-3.147,7.585l5.5,5.5L6.928,41.216a5.555,5.555,0,0,0,7.856,7.856l25.13-25.13,5.5,5.5A4.443,4.443,0,0,0,53,26.3V7.449A4.451,4.451,0,0,0,48.551,3ZM94.667,3a8.336,8.336,0,0,0-8.333,8.333V94.667a8.333,8.333,0,1,0,16.667,0V11.333A8.336,8.336,0,0,0,94.667,3ZM66.889,47.444a8.336,8.336,0,0,0-8.333,8.333V94.667a8.333,8.333,0,1,0,16.667,0V55.778A8.336,8.336,0,0,0,66.889,47.444ZM39.111,69.667A8.336,8.336,0,0,0,30.778,78V94.667a8.333,8.333,0,1,0,16.667,0V78A8.336,8.336,0,0,0,39.111,69.667ZM11.333,80.778A8.336,8.336,0,0,0,3,89.111v5.556a8.333,8.333,0,1,0,16.667,0V89.111A8.336,8.336,0,0,0,11.333,80.778Z" transform="translate(-3 -3)" fill="#f7d070"/>
          </svg>
        `
    },
    {
      value: 'CM',
      label:'Creative & Marketing',
      icon:`
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="50" viewBox="0 0 100 95.223">
            <path id="icons8_illustrator" d="M76.374,1.99a5.005,5.005,0,0,0-4.781,2.679L53.926,37.255c-8.18.628-14.354,3.709-18.371,8.3-4.517,5.164-6.339,11.52-7.743,17.51s-2.419,11.781-4.2,16.054c-1.7,4.059-3.66,6.558-7.607,8.066H7.006a5.006,5.006,0,1,0,0,10.012H17.018c17.393,0,32.9.3,45.209-5.475A31.394,31.394,0,0,0,77.039,77.6c3.24-6.262,4.841-14.155,5-23.963l19.339-33.75a5,5,0,0,0-2.142-6.893L78.271,2.5A4.968,4.968,0,0,0,76.374,1.99ZM56.028,47.218l15.927,7.959c-.3,7.838-1.662,13.685-3.8,17.823a20.5,20.5,0,0,1-10.178,9.65c-6.475,3.038-16.429,3.826-27.581,4.155a30.766,30.766,0,0,0,2.454-3.822c2.444-5.851,3.413-12.088,4.713-17.628s2.907-10.217,5.524-13.209C45.567,49.307,49.061,47.41,56.028,47.218Z" transform="translate(-2 -1.978)" fill="#f7d070"/>
          </svg>
        `
    },
    {
      value:'MS',
      label:'Medical & Science',
      icon:`
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="50" viewBox="0 0 100 90.104">
            <path id="icons8_heart_with_pulse" d="M73.284,3.026A29.932,29.932,0,0,0,52,13.451,29.886,29.886,0,0,0,29.257,3a25.343,25.343,0,0,0-15.241,5.82C2.095,18.192.51,28.337,3.015,38H20.336L29,31.5a5,5,0,0,1,7.16,1.225l7.07,10.6L47.673,40a9.984,9.984,0,0,1,6-2h4.72a10,10,0,1,1,0,10.006h-4.72L45,54.5a5,5,0,0,1-7.16-1.225l-7.07-10.6L26.336,46a9.984,9.984,0,0,1-6,2H7.12c7.57,13.821,21.411,25.4,26.287,29.952,5.165,4.81,11.536,10.526,15.321,13.9a4.9,4.9,0,0,0,6.545,0c3.785-3.37,10.161-9.086,15.321-13.9,8.6-8.015,45.163-37.872,25.852-63.394C90.9,7.226,82.475,2.651,73.284,3.026Z" transform="translate(-2 -3)" fill="#f7d070"/>
          </svg>
        `
    },


    {
      value:'IM',
      label:'Industrial & Manufacturing',
      icon:`
          <svg xmlns="http://www.w3.org/2000/svg" width="58.53" height="52.428" viewBox="0 0 65.53 62.428">
            <path id="icons8_maintenance" d="M54.656,3a13.974,13.974,0,0,0-12.6,19.758L7.836,56.975a4.952,4.952,0,0,0,7,7l19.409-19.41,18.643,18.65A6.795,6.795,0,0,0,62.5,53.61L43.85,34.966l5.2-5.2A13.965,13.965,0,0,0,68.4,20.4a13.671,13.671,0,0,0,.1-6.3,1.777,1.777,0,0,0-2.989-.793l-3.7,3.7a4.952,4.952,0,0,1-7-7l3.7-3.7a1.776,1.776,0,0,0-.793-2.989A13.578,13.578,0,0,0,54.656,3ZM9.388,4.02A3.509,3.509,0,0,0,7.254,5.032L4.313,7.973a3.5,3.5,0,0,0-.438,4.418l3.331,5a7,7,0,0,0,5.827,3.119h1.806l9.479,9.479,4.951-4.951L19.79,15.557V13.752a7,7,0,0,0-3.119-5.827l-5-3.331A3.482,3.482,0,0,0,9.388,4.02Z" transform="translate(-3.287 -3)" fill="#f7d070"/>
          </svg>
        `
    },
    {
      value:'H',
      label:'Hospitality',
      icon:`
          <svg xmlns="http://www.w3.org/2000/svg" width="55" height="32.115" viewBox="0 0 55 32.115">
            <path id="icons8_24_hour_room_service" d="M27.464,48.329a2.292,2.292,0,0,0-2.256,2.323v2.292q0,.058,0,.116a25.235,25.235,0,0,0-22.8,22.8H2.292a2.292,2.292,0,1,0,0,4.584H52.708a2.292,2.292,0,1,0,0-4.583h-.116a25.235,25.235,0,0,0-22.8-22.8q0-.058,0-.116V50.652a2.292,2.292,0,0,0-2.327-2.323Z" transform="translate(0 -48.329)" fill="#f7d070"/>
          </svg>
        `
    },
    {
      value:'T',
      label:'Transportation',
      icon:`
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="45.5" viewBox="0 0 55 49.5">
            <path id="icons8_van" d="M10.25,3A8.274,8.274,0,0,0,2,11.25V41.5a2.758,2.758,0,0,0,2.75,2.75H7.64a9.578,9.578,0,0,0,18.971,0H32.39a9.578,9.578,0,0,0,18.971,0h2.89A2.758,2.758,0,0,0,57,41.5V27.036a8.285,8.285,0,0,0-1.982-5.366L41.488,5.89A8.2,8.2,0,0,0,35.22,3Zm22,5.5h4.233L50.619,25H32.25a2.751,2.751,0,0,1-2.75-2.75v-11A2.751,2.751,0,0,1,32.25,8.5ZM17.125,38.75A4.125,4.125,0,1,1,13,42.875,4.13,4.13,0,0,1,17.125,38.75Zm24.75,0a4.125,4.125,0,1,1-4.125,4.125A4.13,4.13,0,0,1,41.875,38.75Z" transform="translate(-2 -3)" fill="#f7d070"/>
          </svg>
        `
    },


    {
      value:'R',
      label:'Retail',
      icon:`
          <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55.027" viewBox="0 0 55 55.027">
            <path id="icons8_shopping_cart" d="M8.53,2l-4.788.038a2.752,2.752,0,1,0,.038,5.5l2.945-.022,9.06,21.742L12.49,34.528a5.805,5.805,0,0,0,4.842,8.743H47.758a2.752,2.752,0,1,0,0-5.5H17.332c-.306,0-.341-.06-.177-.322h.005l3.235-5.18H40.939a5.5,5.5,0,0,0,4.809-2.832l9.909-17.846a2.748,2.748,0,0,0-2.4-4.084H12.678l-1.585-3.81A2.752,2.752,0,0,0,8.53,2Zm8.963,44.022a5.5,5.5,0,1,0,5.5,5.5A5.5,5.5,0,0,0,17.493,46.022Zm27.514,0a5.5,5.5,0,1,0,5.5,5.5A5.5,5.5,0,0,0,45.007,46.022Z" transform="translate(-1.009 -2)" fill="#f7d070"/>
          </svg>
        `
    },
    {
      value:'W',
      label:'Warehousing',
      icon:`
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 55 55.001">
            <path id="icons8_warehouse" d="M54.373,13.319,31.744,3.263a3.052,3.052,0,0,0-2.481,0L6.63,13.322A6.107,6.107,0,0,0,3,18.9V51.889A6.109,6.109,0,0,0,9.111,58H51.889A6.109,6.109,0,0,0,58,51.889V18.9A6.11,6.11,0,0,0,54.373,13.319ZM42.722,51.889H36.611a3.057,3.057,0,0,1-3.056-3.056V42.723a3.057,3.057,0,0,1,3.056-3.056h6.111a3.057,3.057,0,0,1,3.056,3.056v6.111A3.057,3.057,0,0,1,42.722,51.889ZM33.556,33.556H27.444A3.057,3.057,0,0,1,24.389,30.5V24.389a3.057,3.057,0,0,1,3.056-3.056h6.111a3.057,3.057,0,0,1,3.056,3.056V30.5A3.057,3.057,0,0,1,33.556,33.556Zm-6.111,9.167v6.111a3.057,3.057,0,0,1-3.056,3.056H18.278a3.057,3.057,0,0,1-3.056-3.056V42.723a3.057,3.057,0,0,1,3.056-3.056h6.111A3.057,3.057,0,0,1,27.444,42.723Z" transform="translate(-3 -3)" fill="#f7d070"/>
          </svg>
        `
    },

  ],
  jobs:[
    {
      title:'Account Manager I',
      state:'California',
      days:'1',
      category:'M',
      date:'12-3-2020',
      text:'Adecco, the Global Leader in HR services, is partnering with our client "World\'s Largest Search Engine" and we\'re looking for a great individual to join this team. Job Title: Account Manager I Location : Playa Vista, CA Estimated Length: 1...',
      place:' Playa Vista, Alabama'
    },
    {
      title:'Account Manager I',
      days:'2',
      category:'MS',
      text:'Adecco, the Global Leader in HR services, is partnering with our client "World\'s Largest Search Engine" and we\'re looking for a great individual to join this team. Job Title: Account Manager I Location : Playa Vista, CA Estimated Length: 1...',
      place:' Playa Vista, Alabama'
    },
    {
      title:'Account Manager I',
      days:'3',
      category:'AF',
      text:'Adecco, the Global Leader in HR services, is partnering with our client "World\'s Largest Search Engine" and we\'re looking for a great individual to join this team. Job Title: Account Manager I Location : Playa Vista, CA Estimated Length: 1...',
      place:' Playa Vista, California'
    },
    {
      title:'Account Manager I',
      days:'4',
      category:'M',
      text:'Adecco, the Global Leader in HR services, is partnering with our client "World\'s Largest Search Engine" and we\'re looking for a great individual to join this team. Job Title: Account Manager I Location : Playa Vista, CA Estimated Length: 1...',
      place:' Playa Vista, California'
    },
      {
          title:'Account Manager I',
          days:'5',
          category:'MS',
          text:'Adecco, the Global Leader in HR services, is partnering with our client "World\'s Largest Search Engine" and we\'re looking for a great individual to join this team. Job Title: Account Manager I Location : Playa Vista, CA Estimated Length: 1...',
          place:' Playa Vista, California'
      },
      {
          title:'Account Manager I',
          days:'5',
          category:'MS',
          text:'Adecco, the Global Leader in HR services, is partnering with our client "World\'s Largest Search Engine" and we\'re looking for a great individual to join this team. Job Title: Account Manager I Location : Playa Vista, CA Estimated Length: 1...',
          place:' Playa Vista, Arizona'
      },
  ],
  states:[
      "Alaska",
      "Alabama",
      "Arkansas",
      "Arizona",
      "California",
      "Colorado",
      "Connecticut",
      "Washington, DC",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Iowa",
      "Idaho",
      "Illinois",
      "Indiana",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Massachusetts",
      "Maryland",
      "Maine",
      "Michigan",
      "Minnesota",
      "Missouri",
      "Mississippi",
      "Montana",
      "North Carolina",
      "North Dakota",
      "Nebraska",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "Nevada",
      "New York",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Virginia",
      "Vermont",
      "Washington",
      "Wisconsin",
      "West Virginia",
      "Wyoming"
  ],
  counter:0,
  currentPage:window.location.pathname!=='/'?window.location.pathname:'/seeker/home',
  setState:(set,st=store.state)=> {
    store.state=set(st)
  },
  setCounter:value=>{
    store.counter=value
  },
  setCurrentPage:value=>{
    store.currentPage=value
  },
  updateCurrentPage:()=>{
      store.currentPage=window.location.pathname!=='/'?window.location.pathname:'/employer/home'
  }
})

Vue.prototype.$store=store;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
