
<script>

  import Main from "@/components/Main";
    import CardHeader from "@/components/CardHeader";
      import FormSection from "@/components/FormSection";
    import Input from "@/components/Input";
  import VueTailWindPicker from 'vue-tailwind-picker'
  import Select from "../../components/Select";
  import SelectState from "../../components/SelectState";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import axios from "axios";


export default {
  name: "EmployeerForm",
  components:{
    Input,
    Main,CardHeader,FormSection
  },
  created(){
      let hash=window.location.hash.split('').splice(1).join('')
      this.industry=this.$store.industries.find(i=>i.value=== hash);
  },
  mounted() {
    if(this.form.industry){
      window.scrollTo(0,600)
    }
  },
  data(){
    return{
      form:{
        company:'',
        phone:null,
        email:'',
        industry:'',
        job:'',
        state:null,
        empNo:null,
        date:null,
        salary:null,
        contract:'permanent',
        description:'Write description for the job'
      },
      forms:[
      ],
      selectedForm:null,
      editor:{
        editor: ClassicEditor,
        editorData: '<p>Content of the editor.</p>',
        editorConfig: {
          toolbar: ['bold', 'italic', 'link', 'blockQuote' ],

        }
      },
      industry:'',
      state:'',
      empNo:null,
      temporary:true,
      permanent:false,
      dateTheme:{
        background: '#FFFFFF',
        text: 'text-gray-700',
        border: 'border-gray-200',
        currentColor: 'text-gray-200',
        navigation: {
          background: 'bg-gray-100',
          hover: 'hover:bg-gray-200',
          focus: 'bg-gray-200',
        },
        picker: {
          rounded: 'rounded-full',
          selected: {
            background: 'bg-bony-400',
            border: 'border-bony-500',
            hover: 'date-hover',
          },
          holiday: 'text-bony-600',
          weekend: 'text-bony-600',
          event: 'bg-indigo-500',
        },
        event: {
          border: 'border-gray-200',
        },
      }
    }
  },
  methods:{

    submitForm(){
      axios.post('employers_form.php', this.toFormData(this.form))
          .then( function(response) {
            console.log(response.data)
            if(response.data){
            document.querySelector('.bga').style.display='block'
            document.querySelector('.thank').style.display='flex'
            this.forms=[];
            this.form={
              company:'',
              phone:null,
              email:'',
              industry:'',
              job:'',
              state:null,
              empNo:null,
              date:null,
              salary:null,
              contract:'permanent',
              description:'Write description for the job'
            }
          }
          });
    },
    toFormData: function(obj) {
      let formData = new FormData();
      for(let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    formSubmit(){
      if(this.selectedForm!==null){
        this.forms[this.selectedForm]={...this.form}
        this.selectedForm=null
        this.initializeForm()
        window.scrollTo(0,600)

      }
      else if(this.add){
        this.add=false
        this.forms.push({...this.form,del:false});
        // initialize form
        this.initializeForm()
        //set add to false
      }
      else{
        this.submitForm()

      }
    },
    initializeForm(){
      // this.form.industry=''
      this.form.job=''
      // this.form.state=''
      this.form.empNo=''
      // this.form.date=null
      this.form.salary=null
      this.form.contract='permanent'
      this.form.description='Write description for the job'
    },
    hideBg(){
      document.querySelector('.bga').style.display='none'
      document.querySelector('.thank').style.display='none'
      document.querySelector('.del').style.display='none'
    },
    setIndustry(v){
      this.industry=v
    },
    setState(v){
      this.state=v
    },
    addForm(){
      this.add=true
      document.getElementById('sub').click();
          // this.forms.push(this.form)
      // window.scrollTo(0,700)
    },
    backTo(i){
      this.form=this.forms[i];
      if(this.forms[i].contract==='permanent'){
        this.permanent=true;
      }
      else{
        this.temporary=true
      }
      // this.forms.splice(i,1)
      this.selectedForm=i
      window.scrollTo(0,700)
    },
    deleteJob(){
      document.querySelector('.bga').style.display='block'
      document.querySelector('.del').style.display='flex'
      // this.forms.splice(this.selectedForm,1)
      // this.initializeForm()
    }
  },
  watch:{
    permanent(){
      this.temporary=!this.permanent
      this.form.contract=this.temporary?'temporary':'permanent'
    },
    temporary(){
      this.permanent=!this.temporary
      this.form.contract=this.temporary?'temporary':'permanent'
    },
    industry(){
      this.form.industry=this.industry.label
    },
    state(){
      this.form.state=this.state
    }
  },
  render(){
    return(
        <Main
            // Header Title, Paragraph and Photo
            title="Request talent or tell us about your staffing needs."
            photo={require('@/assets/imgs/cv.jpg')}
            p="Go ahead. We’re great listeners. We have a massive network of pre-qualified candidates, so this is going to work out great for you."
        >

          <div class="container w-full mx-auto bg-white rounded-xl shadow-lg pb-2">
            <CardHeader  title="Employer's Form"/>

            <FormulateForm  onSubmit={()=> {
                (this.form.industry&&this.form.state)?this.formSubmit():window.scrollTo(0,500)
            }}>
              <FormSection title="Contcat info" p1="Providing good and accurate contact information helps us reach you to meet your needs faster." border={true}>
              <div class="mb-4 grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

                <FormulateInput
                    type="text"
                    placeholder="Company Name"
                    name="company"
                    label="Company Name"
                    validation="required"
                    v-model={this.form.company}
                />
                <FormulateInput
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    label="Phone Number"
                    validation="required|number"
                    v-model={this.form.phone}
                />

              </div>

                <FormulateInput
                    type="email"
                    placeholder="example@gmail.com"
                    name="email"
                    label="Email Address"
                    validation="required|email"
                    v-model={this.form.email}
                />
              </FormSection>


                  <CardHeader padding="0"/>


              <FormSection title="Job info" p1="Providing detailed information on the jobs you need will help us get you the right candidates and get them onboard faster." border={true}>

                <div class="mb-4 grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

                  <div>
                    <Select
                        data={this.$store.industries}
                        label="Indusry's Name"
                        place="Industry"
                        selected={this.industry}
                        set={this.setIndustry}
                    />
                    <span v-show={!this.form.industry} class="text-red-700 text-xs my-1">industry is required</span>
                </div>

                  <FormulateInput
                      type="text"
                      placeholder="Job Name"
                      name="job"
                      label="Job Name"
                      validation="required"
                      v-model={this.form.job}
                  />

                </div>

                <div class="mb-4 grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

                  <div>
                    <SelectState
                        data={this.$store.states}
                        label="Location"
                        place="State"
                        selected={this.state}
                        set={this.setState}
                    />
                    <span v-show={!this.form.state} class="text-red-700 text-xs my-1">state is required</span>
                  </div>

                  <FormulateInput
                      type="text"
                      placeholder="0"
                      name="Employees Number"
                      label="Number of employees"
                      input-class={["m"]}
                      min={0}
                      validation="required|number"
                      v-model={this.form.empNo}
                  />


                </div>

                <div class="mb-4 grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">

                  <div class="flex flex-col">
                    <label class="px-2 text-gray-600">Deadline for job</label>
                    <VueTailWindPicker theme={this.dateTheme} onChange={(v) => this.form.date = v}>
                      <input type="text" v-model={this.form.date} class='border-bony-500 bg-gray-100 mt-2 rounded-md flex-1 px-2 border-b-2 leading-none outline-none border-box w-full mb-1 date' />
                    </VueTailWindPicker>
                  </div>

                  <FormulateInput
                      type="text"
                      placeholder="Hourly Rate"
                      name="salary"
                      label="Hourly Rate"
                      validation="required|number"
                      v-model={this.form.salary}

                  />

                </div>



                <h1 class="mb-4 px-2 text-gray-600">Job's type</h1>

                <div class="flex justify-between w-3/5 flex-col md:flex-row lg:flex-row pl-2">

                  <label class="flex justify-start items-center cursor-pointer">
                    <div class={`bg-white rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 border border-gray-500 ${this.temporary?'bg-white border-bony-500':'border border-gray-500'}`}>
                      <input v-model={this.temporary} type="checkbox" class="opacity-0 absolute cursor-pointer"/>
                        <svg class="hidden" height="11" width="11">
                          <circle cx="5.2" cy="5.2" r="4" fill="#e9b949" />
                        </svg>
                    </div>
                    <div class="select-none">Temporary</div>
                  </label>

                  <label class="flex justify-start items-center cursor-pointer">
                    <div
                        class={`bg-white rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 border border-gray-500 ${this.permanent ? 'bg-white border-bony-500' : 'border border-gray-500'}`}>
                      <input v-model={this.permanent} type="checkbox" class="opacity-0 absolute cursor-pointer"/>
                      <svg class="hidden" height="11" width="11">
                        <circle cx="5.2" cy="5.2" r="4" fill="#e9b949"/>
                      </svg>
                    </div>
                    <div class="select-none">Permanent</div>
                  </label>


                </div>


                <h1 class="mb-1 mt-6 px-2 text-gray-600">Description</h1>




                <ckeditor editor={this.editor.editor} v-model={this.form.description} config={this.editor.editorConfig}></ckeditor>

              </FormSection>

                <div onClick={this.addForm} class="hidden border mx-auto w-11/12 border-grey-200 flex items-center cursor-pointer justify-center text-grey-500 rounded-lg mt-1 mb-4 h-32">
                  Add another Job
                </div>
                <CardHeader padding="0"/>

                <div class="flex flex-col md:flex-row justify-between px-5">

                  <ul class="flex px-2">
                    {this.forms.map((i,index)=><li onClick={()=>this.backTo(index)} key={i.description} class={`w-4 mx-1 text-bony-500 text-sm flex items-center justify-center h-4 mt-2 rounded-full ${this.selectedForm==index&&' text-bony-800'}`}>
                      {index+1}
                    </li>)}
                  </ul>

                  <div class="flex justify-center items-center">
                    <FormulateInput v-show={this.forms.length} onClick={this.deleteJob} value="Delete" type="button" input-class="w-max text-grey-800 btn font-bold py-2.5 px-8 my-4 mx-2 rounded-lg"/>
                    <FormulateInput id="sub" type="submit" input-class="bg-bony-400 text-bony-800 btn font-bold hover:text-white py-2.5 px-8 my-4 border hover:border-transparent rounded-lg"/>
                  </div>

                </div>



            </FormulateForm>

            </div>


          <div class="bga hidden" onClick={this.hideBg}></div>
          <div style="background:#EFFCF6;" class="w-1/2 hidden fixed thank animated bounceInUp top-1/4 left-1/4 flex items-center shadow-md rounded-lg mb-6 flex-col p-6">

            <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 125 125">
              <g id="Group_314" data-name="Group 314" transform="translate(27 8.116)">
                <circle id="Ellipse_8" data-name="Ellipse 8" cx="62.5" cy="62.5" r="62.5"
                        transform="translate(-27 -8.116)" fill="#65d6ad" opacity="0.5"/>
                <path id="icons8_ok"
                      d="M32.619,2A30.619,30.619,0,1,0,63.239,32.619,30.619,30.619,0,0,0,32.619,2ZM50.094,25.6,28.66,47.032a3.06,3.06,0,0,1-4.33,0l-9.186-9.186a3.061,3.061,0,0,1,4.33-4.33L26.5,40.537,45.764,21.269a3.061,3.061,0,0,1,4.33,4.33Z"
                      transform="translate(2.812 21.904)" fill="#0c6b58"/>
              </g>
            </svg>


            <h1 style="color:#147D64" class="text-xl text-center font-bold my-4">
              Thank you!
              <span class="text-sm text-black block font-normal">
                Your submission has been sent.
              </span>
            </h1>
          </div>

          <div style="background:#FEF6F6;"
               class="w-1/2 del hidden fixed animated bounceInUp top-1/4 left-1/4 flex items-center shadow-md rounded-lg mb-6 flex-col p-6">

            <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 125 125">
              <g id="Group_314" data-name="Group 314" transform="translate(-351.964 -1031.964)">
                <circle id="Ellipse_8" data-name="Ellipse 8" cx="62.5" cy="62.5" r="62.5" transform="translate(351.964 1031.964)" fill="#e46464" opacity="0.36"/>
                <g id="Group_316" data-name="Group 316" transform="translate(383.738 1063.738)">
                  <path id="icons8_box_important_1" d="M34.159,3.433A30.726,30.726,0,1,0,64.885,34.159,30.725,30.725,0,0,0,34.159,3.433ZM35.7,49.522H32.623a1.537,1.537,0,0,1-1.536-1.536V44.913a1.537,1.537,0,0,1,1.536-1.536H35.7a1.537,1.537,0,0,1,1.536,1.536v3.073A1.537,1.537,0,0,1,35.7,49.522Zm-1.536-12.29h0a3.074,3.074,0,0,1-3.073-3.073V21.869A3.074,3.074,0,0,1,34.159,18.8h0a3.074,3.074,0,0,1,3.073,3.073v12.29A3.074,3.074,0,0,1,34.159,37.232Z" transform="translate(-3.433 -3.433)" fill="#881b1b"/>
                </g>
              </g>
            </svg>

            <ul style="width:90%">
              {this.forms.map(i=>(
                <li class="flex items-center mt-1">
                  <label class="flex justify-start items-center cursor-pointer">
                    <div
                        class={`bg-white rounded w-4 h-4 flex flex-shrink-0 justify-center items-center mr-2 ${i.del ? 'bg-bony-500' : 'border border-gray-500'}`}>
                      <input  v-model={i.del} type="checkbox"
                             class="opacity-0 absolute cursor-pointer"/>
                      <svg class="hidden w-3 h-3 text-green-500 pointer-events-none"
                           viewBox="0 0 20 20" fill="#fff">
                        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                      </svg>
                    </div>
                    <div class="select-none">{i.job}</div>
                  </label>
                </li>
              ))}
            </ul>

            <h1 style="color:#147D64" class="text-xl text-center font-bold my-4">
              <span class="text-sm text-black block font-normal">
                Do you want to delete the selected jobs?
              </span>
            </h1>
            <FormulateInput
                outer-class="self-end"
                onClick={
              ()=>{
                this.forms=this.forms.filter(i=>!i.del)
                this.hideBg()
              }
            } type="button" value="delete" input-class="bg-red-300 text-grey-800 btn font-bold py-2 px-6 my-1 border hover:border-transparent rounded-lg"/>

          </div>


        </Main>
    )
  }
}
</script>

<style lang="scss">
.select{
  background: #f3f4f6 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='55' height='24.911' viewBox='0 0 25 14.911'%3E%3Cpath id='icons8_expand_arrow' d='M18.869,19.11l-8.481-8.481a2.355,2.355,0,0,0-3.329,0h0a2.355,2.355,0,0,0,0,3.329L17.3,24.2a2.217,2.217,0,0,0,3.139,0L30.679,13.958a2.355,2.355,0,0,0,0-3.329h0a2.355,2.355,0,0,0-3.329,0Z' transform='translate(-6.369 -9.939)' fill='%237e7e7e'/%3E%3C/svg%3E%0A") no-repeat right .75rem center/13px 15px !important;
  appearance: none;
  padding: 0.660rem;
}
.date{
  background: #f3f4f6 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='27.778' viewBox='0 0 25 27.778'%3E%3Cpath id='icons8_calendar' d='M8.556,1A1.389,1.389,0,0,0,7.167,2.389V3.778H5.778A2.786,2.786,0,0,0,3,6.556V26a2.786,2.786,0,0,0,2.778,2.778H25.222A2.786,2.786,0,0,0,28,26V6.556a2.786,2.786,0,0,0-2.778-2.778H23.833V2.389a1.389,1.389,0,0,0-2.778,0V3.778H9.944V2.389A1.389,1.389,0,0,0,8.556,1ZM5.778,10.722H25.222V24.611A1.389,1.389,0,0,1,23.833,26H7.167a1.389,1.389,0,0,1-1.389-1.389Z' transform='translate(-3 -1)' fill='%237e7e7e'/%3E%3C/svg%3E%0A") no-repeat right .75rem center/13px 15px !important;
  appearance: none;
  padding: 8px 0.660rem;
}
.z-10{
  @apply text-bony-800
}
.number{
  background: #f3f4f6 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='41.911' viewBox='0 0 25 41.911'%3E%3Cg id='Group_195' data-name='Group 195' transform='translate(-1213 -374)'%3E%3Cpath id='icons8_expand_arrow' d='M18.869,19.11l-8.481-8.481a2.355,2.355,0,0,0-3.329,0h0a2.355,2.355,0,0,0,0,3.329L17.3,24.2a2.217,2.217,0,0,0,3.139,0L30.679,13.958a2.355,2.355,0,0,0,0-3.329h0a2.355,2.355,0,0,0-3.329,0Z' transform='translate(1206.632 391.06)' fill='%237e7e7e'/%3E%3Cpath id='icons8_expand_arrow-2' data-name='icons8_expand_arrow' d='M18.869,19.11l-8.481-8.481a2.355,2.355,0,0,0-3.329,0h0a2.355,2.355,0,0,0,0,3.329L17.3,24.2a2.217,2.217,0,0,0,3.139,0L30.679,13.958a2.355,2.355,0,0,0,0-3.329h0a2.355,2.355,0,0,0-3.329,0Z' transform='translate(1244.369 398.851) rotate(180)' fill='%237e7e7e'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat right 0.75rem center/13px 15px !important;
  appearance: textfield;
  //padding: 0.660rem;
}
input:checked + svg {
  display: block;
}
.bga{
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left:0;
}
//option{
//  background:#e1e1e1;
//}
//.option:hover{
//  color:red !important;
//}
//select {
//  background-color: #c0392b;
//  appearance: none;
//  -webkit-appearance: none;
//  -moz-appearance: none;
//}
//select:hover,
//select:focus {
//  color: #7e7e7e;
//  background-color: #e1e1e1;
//  border-bottom-color: #DCDCDC;
//}
</style>

