
<script>

import Main from '@/components/Main'
import CardHeader from '@/components/CardHeader'
import SmallCard from '@/components/SmallCard'
import Tabs from "../../components/Tabs";
import Tab from "../../components/Tab";
import SolutionSection from "../../components/FormSection";
import JobCard from "../../components/JobCard";
import VueTailWindPicker from 'vue-tailwind-picker'
import { CheckboxSvgMap  } from "vue-svg-map";
import USA from "@svg-maps/usa";
import Map from "../../components/Map";
import Select from "../../components/Select";
import SelectState from "../../components/SelectState";

export default {
name: "AllJobs",
  components:{
    Map,
    JobCard,
    SolutionSection,
    Tab,
    Tabs,
    Main,
    SmallCard,
    CardHeader,
    VueTailWindPicker,
    CheckboxSvgMap
  },
  data(){
    return{
      jobs:this.$store.jobs,
      dateTheme:{
        background: '#FFFFFF',
        text: 'text-gray-800',
        border: 'border-gray-200',
        currentColor: 'text-gray-800',
        navigation: {
          background: 'bg-gray-100',
          hover: 'hover:bg-gray-200',
          focus: 'bg-gray-200',
        },
        picker: {
          rounded: 'rounded-full',
          selected: {
            background: 'bg-bony-400',
            border: 'border-bony-500',
            hover: 'date-hover',
          },
          holiday: 'text-bony-600',
          weekend: 'text-bony-600',
          event: 'bg-indigo-500',
        },
        event: {
          border: 'border-gray-200',
        },
      },
      USA:USA,
      selectedLocations:[],

      //Filters
      category:'',
      date:'Dead Line Date',
      state:'',
      city:'',
      contract:'',
      title:'',
      industry:'',
      tempState:'',

    }
  },
  created(){
  },
  mounted() {
    let hash=window.location.hash.split('').splice(1).join('')
    this.setCategory(this.$store.industries.filter(i=>i.value===hash)[0]);
    if(this.category){
      window.scrollTo(0,400)
    }
  },
  methods:{
    setTitle(v){
      this.title=v
    },
    setCategory(v){
      this.category=v
    },
    setState(v){
      this.state=v!=='any'?v:'';
    },
    setContract(v){
      this.contract=v!=='any'?v:'';
    },
    filter(date,category,state,city,contract,title){
      if(date||category||state||city||contract||title){
        this.jobs=this.$store.jobs.filter(i=>
            ((date&&date!=='Dead Line Date')?date === i.date:1)&&
            (category?category === i.category:1)&&
            (state?i.place.includes(state):1)&&
            (city?i.place.includes(city):1)&&
            (title?i.title.includes(title):1)&&
            (contract?contract === i.contract:1)
        );
      }
      else{
        this.jobs=this.$store.jobs;
      }
    },
  },
  watch:{
    category(){
      this.industry=this.category.value
      this.filter(this.date,this.industry,this.state,this.city,this.contract,this.title)
    },
    date(){
      this.filter(this.date,this.industry,this.state,this.city,this.contract,this.title)
    },
    state(){
      this.filter(this.date,this.industry,this.state,this.city,this.contract,this.title)
    },
    city(){
      this.filter(this.date,this.industry,this.state,this.city,this.contract,this.title)
    },
    contract(){
      this.filter(this.date,this.industry,this.state,this.city,this.contract,this.title)
    },
    title(){
      this.filter(this.date,this.industry,this.state,this.city,this.contract,this.title)
    }
  },
  render(){
    return(

        <Main
            // Header Title, Paragraph and Photo
            title="We’ll help you succeed in the job market"
            photo={require('@/assets/imgs/all.jpg')}
            p={`
              We’re here to help you navigate today’s job market!
              Whether you’ve been searching for some time or are just starting your search,
              MetroJobs will provide direction, skills,
              resources and other job seeker assistance to ensure you can effectively
              and confidently present your skills and value to potential employers.
            `}
        >

            <div class="container w-full mx-auto bg-white rounded-xl shadow-lg pb-6  overflow-hidden">


                <tabs>

                  <tab
                      // View all jobs Tab
                      name="View all jobs"
                      selected={true}
                  >

                    <CardHeader
                        padding="10"
                        title="Find a job"
                        search={true}
                        searchBar={this.setTitle}
                    />

                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">


                      {/* Filtering Form */}

                      <div class="flex flex-col px-4 py-2 my-4 items-stretch md:flex-col">

                        <Select
                            data={[{label:'all',value:''},...this.$store.industries]}
                            place="Industry"
                            selected={this.category}
                            set={this.setCategory}
                        />

                        <SelectState
                            data={['any',...this.$store.states]}
                            place="State"
                            selected={this.state}
                            set={this.setState}
                        />

                        <SelectState
                            data={['any','permanent','temporary']}
                            place="Contract"
                            selected={this.contract}
                            set={this.setContract}
                        />

                        <VueTailWindPicker
                            // Date Picker
                            init={false}
                            class="datePicker"
                            onChange={v=>this.date=v}
                            theme={this.dateTheme}
                        >
                          <input
                              onClick={()=>this.date='Dead Line Date'}
                              type="text"
                              v-model={this.date}
                              class='cursor-pointer w-full border-bony-500 bg-gray-100 p mt-1 rounded-md flex-1 px-2.5 border-b-2 text-grey-300 leading-none outline-none border-box md:w-full mb-1 date'
                          />
                        </VueTailWindPicker>

                      </div>


                      <div style="height:85vh;overflow:auto;scrollbar-width:thin" class="flex flex-col items-center pt-6 col-span-1 px-8 lg:col-span-2 mb-6">

                        {
                          //Rendering All of Jobs' Cards
                          this.jobs.length?
                            this.jobs.map(job=>
                                <JobCard
                                  title={job.title}
                                  days={job.days}
                                  text={job.text}
                                  place={job.place}
                                  key={job.days}
                                />)
                                  :<div class="w-full bg-red-50 flex items-center shadow-md rounded-lg mb-6 flex-col p-6">

                                      <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 125 125">
                                        <g id="Group_314" data-name="Group 314" transform="translate(-351.964 -1031.964)">
                                          <circle id="Ellipse_8" data-name="Ellipse 8" cx="62.5" cy="62.5" r="62.5" transform="translate(351.964 1031.964)" fill="#e46464" opacity="0.36"/>
                                          <g id="Group_316" data-name="Group 316" transform="translate(383.738 1063.738)">
                                            <path id="icons8_box_important_1" d="M34.159,3.433A30.726,30.726,0,1,0,64.885,34.159,30.725,30.725,0,0,0,34.159,3.433ZM35.7,49.522H32.623a1.537,1.537,0,0,1-1.536-1.536V44.913a1.537,1.537,0,0,1,1.536-1.536H35.7a1.537,1.537,0,0,1,1.536,1.536v3.073A1.537,1.537,0,0,1,35.7,49.522Zm-1.536-12.29h0a3.074,3.074,0,0,1-3.073-3.073V21.869A3.074,3.074,0,0,1,34.159,18.8h0a3.074,3.074,0,0,1,3.073,3.073v12.29A3.074,3.074,0,0,1,34.159,37.232Z" transform="translate(-3.433 -3.433)" fill="#881b1b"/>
                                          </g>
                                        </g>
                                      </svg>

                                    <h1 style="color:#5F1919" class="text-xl text-center font-bold my-4">
                                      Oops!
                                      <span class="text-sm text-black block font-normal">
                                        We could not found results for your search
                                      </span>
                                    </h1>
                                  </div>
                          }

                      </div>

                    </div>

                  </tab>


                  <tab name="Browse our Jobs">

                    <div class="relative w-11/12 mx-auto shadow-md rounded-lg my-8 border-4 overflow-hidden border-bony-600">

                      <Map click={this.setState}/>


                      <div
                          v-show={this.state}
                           style="height:98%;overflow:auto;scrollbar-width:thin"
                           class="md:absolute  w-full md:w-1/2 m-1 rounded-lg bg-white top-0 flex flex-col items-center py-4 col-span-1 px-8 lg:col-span-2 mb-6 ">
                        <div class="flex items-end w-full mb-2">
                          <h1 onClick={() => this.state = ''}
                              class="text-right flex-1 hover:text-bony-600 cursor-pointer">X</h1>
                        </div>
                        {
                          //Rendering All of Jobs' Cards
                          this.jobs.length ?
                              this.jobs.map(job =>
                                  <JobCard
                                      title={job.title}
                                      days={job.days}
                                      text={job.text}
                                      place={job.place}
                                      key={job.days}
                                  />)
                              : <div
                                  class="w-full bg-red-50 flex items-center shadow-md rounded-lg mb-6 flex-col p-6">

                                <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 125 125">
                                  <g id="Group_314" data-name="Group 314" transform="translate(-351.964 -1031.964)">
                                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="62.5" cy="62.5" r="62.5"
                                            transform="translate(351.964 1031.964)" fill="#e46464" opacity="0.36"/>
                                    <g id="Group_316" data-name="Group 316" transform="translate(383.738 1063.738)">
                                      <path id="icons8_box_important_1"
                                            d="M34.159,3.433A30.726,30.726,0,1,0,64.885,34.159,30.725,30.725,0,0,0,34.159,3.433ZM35.7,49.522H32.623a1.537,1.537,0,0,1-1.536-1.536V44.913a1.537,1.537,0,0,1,1.536-1.536H35.7a1.537,1.537,0,0,1,1.536,1.536v3.073A1.537,1.537,0,0,1,35.7,49.522Zm-1.536-12.29h0a3.074,3.074,0,0,1-3.073-3.073V21.869A3.074,3.074,0,0,1,34.159,18.8h0a3.074,3.074,0,0,1,3.073,3.073v12.29A3.074,3.074,0,0,1,34.159,37.232Z"
                                            transform="translate(-3.433 -3.433)" fill="#881b1b"/>
                                    </g>
                                  </g>
                                </svg>

                                <h1 style="color:#5F1919" class="text-xl text-center font-bold my-4">
                                  Oops!
                                  <span class="text-sm text-black block font-normal">
                                        We could not found results for your search
                                      </span>
                                </h1>
                              </div>
                        }

                      </div>


                    </div>

                  </tab>

              </tabs>

            </div>

        </Main>

    )
  }
}
</script>

<style lang="scss">
.pad{
  padding-right: 2rem !important;
}
.p{
  padding:9px !important;
}
.datePicker{
  #v-tailwind-picker .w-88[data-v-36a4ff6a]{
    width: auto !important;
  }
}
.svg-map {
  width: 100%;
  height: auto;
  stroke: #666;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round; }
.svg-map__location {
  fill: #f7d070;
  cursor: pointer; }
.svg-map__location:focus, .svg-map__location:hover {
  fill: #f8e3a3;
  outline: 0; }
.svg-map__location[aria-checked="true"] {
  fill: #a27c1a; }
select{
  //@apply text-grey-300
}
</style>