<template>
  <div class="flex flex-1 flex-col md:flex-row m-4 p-4 w-full items-center bg-bony-400 rounded-lg shadow">
    <h1 class="font-semibold text-9xl text-bony-800">{{ number }}</h1>
    <p class="text-center text-white px-5">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SmallCardNumber",
  props:{
    number:String,
    text:String,
  }
}
</script>

<style scoped>
.cardTitle{
  font-size: 22px;
  color:#513c06;
}
.bg-bony{
  background:#c99a2e ;
}
</style>