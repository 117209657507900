<template>
     <div class="grid grid-cols-1 lg:grid-cols-5 py-4">
      <div class="col-span-2 mr-2 ml-6 mb-4">
        <h1 class="text-lg text-bony-600 font-semibold">
          {{ title }}
        </h1>
        <p class="text-gray-400 py-2 text-md">
          {{ p1 }}
        </p>
      </div>

      <div class="ml-2 mr-6 col-span-3">
        <slot></slot>
      </div>

    </div>
</template>

<script>
export default {
name: "SolutionSection",
  props:{
    title:String,
    p1:String,
    border:Boolean
  }
}
</script>

<style scoped>
.color{
  color:#cb9e37;
}
.text-md{
  font-size: 12px;
  font-weight:500;
}
.btn{
  border-color: #cda240;
  color:#7d5f12;
  border-width: 1.5px;
}
.btn:hover{
  background: #cda240;
}
</style>