<script>

import Navbar from "@/components/Navbar"
import Navbar2 from "@/components/SecondNavbar"
import Footer from "@/components/Footer"
import Home from "@/pages/Employer/EmployerHome"
import OurSolutions from "@/pages/Employer/OurSolutions"

export default {
  name: 'App',
  components: {
    Navbar,Navbar2,Home,Footer,OurSolutions
  },
  data(){
    return{
      // isIt:false,

    }
  },
  mounted() {

  },
  created(){
      if(!(window.location.pathname.includes('/')||window.location.pathname.includes('/seeker'))){
        window.location.pathname='employer'
      }
  },
  computed: {

  },
  methods:{

  },
  render(){
    // const $store=this.$store;
    return(
        <div style="background:#e1e1e1">
          <Navbar logo={require('./assets/imgs/logo.svg')} links={[{name:'Seeker',to:'/seeker/home',temp:'Are You a Seeker?'}, {name:'Employer',to:'/employer/home',temp:'Are You an Employer?'},]} />

          <router-view className="view"></router-view>

          <Footer/>
        </div>
    )
  }
}

// <button
//     className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
//   Button
// </button>

// <Navbar logo={require('./assets/logo.png')} name="Vue JS" links={['Home','Gallary','Contact us']} profile={{img:require('./assets/1.jpg'),name:'Ali Abdo'}}>
//
//   <svg class="cursor-pointer" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M11.0001 10.8231C9.84668 9.66927 9.0612 8.19945 8.74288 6.59937C8.42456 4.99928 8.58769 3.34075 9.21164 1.83334C7.43263 2.18356 5.79852 3.05646 4.51831 4.34043C0.938722 7.92001 0.938722 13.7243 4.51831 17.3039C8.09881 20.8844 13.9022 20.8835 17.4827 17.3039C18.7663 16.0238 19.6392 14.3901 19.9898 12.6115C18.4824 13.2353 16.8239 13.3984 15.2238 13.0801C13.6238 12.7618 12.1539 11.9764 11.0001 10.8231V10.8231Z" fill="#f2db21"/>
//   </svg>
// </Navbar>
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
