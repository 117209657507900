<template>
  <div>
    <!--  Navbar from seeker  -->
    <SecondNavbar part="seeker" :links="links"/>

    <!--  Routing using v-if  -->
      <Industry v-if="$route.params.id==='industries'"/>
      <ContactUs v-else-if="$route.params.id==='contact-us'"/>
      <HowToApply v-else-if="$route.params.id==='how-to-apply'"/>
      <AllJobs v-else-if="$route.params.id==='all-jobs'"/>

      <div  v-else-if="$route.params.id==='apply-now'" class="w-3/4 my-28 mx-auto bg-red-50 flex items-center shadow-md rounded-lg mb-6 flex-col p-6">

        <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 125 125">
          <g id="Group_314" data-name="Group 314" transform="translate(-351.964 -1031.964)">
            <circle id="Ellipse_8" data-name="Ellipse 8" cx="62.5" cy="62.5" r="62.5" transform="translate(351.964 1031.964)" fill="#e46464" opacity="0.36"/>
            <g id="Group_316" data-name="Group 316" transform="translate(383.738 1063.738)">
              <path id="icons8_box_important_1" d="M34.159,3.433A30.726,30.726,0,1,0,64.885,34.159,30.725,30.725,0,0,0,34.159,3.433ZM35.7,49.522H32.623a1.537,1.537,0,0,1-1.536-1.536V44.913a1.537,1.537,0,0,1,1.536-1.536H35.7a1.537,1.537,0,0,1,1.536,1.536v3.073A1.537,1.537,0,0,1,35.7,49.522Zm-1.536-12.29h0a3.074,3.074,0,0,1-3.073-3.073V21.869A3.074,3.074,0,0,1,34.159,18.8h0a3.074,3.074,0,0,1,3.073,3.073v12.29A3.074,3.074,0,0,1,34.159,37.232Z" transform="translate(-3.433 -3.433)" fill="#881b1b"/>
            </g>
          </g>
        </svg>

        <h1 style="color:#5F1919" class="text-xl text-center font-bold my-4">
          Oops!
          <span class="text-sm text-black block font-normal">
              This Page is not found 404
          </span>
        </h1>
      </div>

      <SeekerHome v-else/>
  </div>
</template>
<script>
import SecondNavbar from "@/components/SecondNavbar";
import Industry from "@/pages/Industry";
import ContactUs from "../ContactUs";
import SeekerHome from "./SeekerHome";
import HowToApply from "./HowToApply";
import AllJobs from "./AllJobs";

export default {
name: "Employer",
  components: {AllJobs, HowToApply, SeekerHome, ContactUs, Industry, SecondNavbar},
  data(){
  return{
    links:[
      {name:'Home',to:'home'},
      {name:'Industries ',to:'industries'},
      {name:'How To Apply ',to:'how-to-apply'},
      {name:'Apply Now',to:'https://metrojobsonline.securedportals.com/apply'},
      {name:'Find a Job',to:'https://metrojobsonline.securedportals.com/jobportal/jobs'},
      {name:'Contact us',to:'contact-us'}
    ]
  }
  }
}
</script>

<style scoped>

</style>