<template>
  <div class="px-40 w-full bg-footer flex flex-col items-center">
    <div class="flex flex-col xl:flex-row lg:flex-row md:flex-row w-full items-center justify-between px-4 mb-4">
      <img class="w-64 mt-6 max-w-sm" src="../assets/imgs/logo 2.svg" alt="logo"/>
      <div class="flex flex-col w-56 my-4">
        <h3 style="color:#fcefc7" class="text-xl font-semibold">Keep in Touch</h3>
        <div class="flex w-full my-3 items-center justify-around mt-4">

          <svg xmlns="http://www.w3.org/2000/svg" width="278" height="30" viewBox="0 0 278 30">
            <g id="Group_217" data-name="Group 217" transform="translate(-1388 -4069)">
                <g @click="goTo('facebook')" class="cursor-pointer" id="icons8_facebook" transform="translate(1388 4069)">
                  <path id="icons8_facebook-2" data-name="icons8_facebook" d="M16.5,2.008a14.992,14.992,0,0,0-1.74,29.808V20.975H12.338A1.97,1.97,0,0,1,10.367,19h0a1.97,1.97,0,0,1,1.971-1.971h2.42V14.41c0-4.344,2.117-6.251,5.727-6.251.536,0,.993.012,1.382.031a1.789,1.789,0,0,1,1.694,1.8h0a1.8,1.8,0,0,1-1.8,1.8H21.1c-1.533,0-2.069,1.454-2.069,3.092v2.156h2.805a1.446,1.446,0,0,1,1.43,1.667L23.1,19.753a1.447,1.447,0,0,1-1.43,1.226H19.028V31.849A14.993,14.993,0,0,0,16.5,2.008Z" transform="translate(-2 -2)" fill="#fcefc7"/>
                </g>
              <g @click="goTo('insta')" class="cursor-pointer" id="Component_9_5" data-name="Component 9 – 5" transform="translate(1636 4069)">
                <path id="Exclusion_3" data-name="Exclusion 3" d="M15,30A15,15,0,0,1,4.393,4.393,15,15,0,1,1,25.606,25.606,14.9,14.9,0,0,1,15,30ZM12.167,8A4.172,4.172,0,0,0,8,12.167v6.666A4.172,4.172,0,0,0,12.167,23h6.666A4.172,4.172,0,0,0,23,18.833V12.167A4.172,4.172,0,0,0,18.833,8ZM15.5,19.667A4.167,4.167,0,1,1,19.667,15.5,4.171,4.171,0,0,1,15.5,19.667ZM15.5,13A2.5,2.5,0,1,0,18,15.5,2.5,2.5,0,0,0,15.5,13Zm5-1.666a.833.833,0,1,1,.833-.833A.834.834,0,0,1,20.5,11.334Z" fill="#fcefc7"/>
              </g>
              <g @click="goTo('gmail')" class="cursor-pointer" id="Component_6_5" data-name="Component 6 – 5" transform="translate(1450 4069)">
                <path id="Exclusion_1" data-name="Exclusion 1" d="M15,30A15,15,0,0,1,4.393,4.393,15,15,0,1,1,25.606,25.606,14.9,14.9,0,0,1,15,30ZM9.5,9A1.5,1.5,0,0,0,8,10.5v9A1.5,1.5,0,0,0,9.5,21h12A1.5,1.5,0,0,0,23,19.5v-9A1.5,1.5,0,0,0,21.5,9Zm6,6.616a.748.748,0,0,1-.4-.114L9.8,12.187a.636.636,0,1,1,.673-1.078L15.5,14.25l5.027-3.142a.632.632,0,0,1,.335-.1.637.637,0,0,1,.338,1.177L15.9,15.5A.748.748,0,0,1,15.5,15.616Z" fill="#fcefc7"/>
              </g>
              <g @click="goTo('twitter')" class="cursor-pointer" id="Component_8_5" data-name="Component 8 – 5" transform="translate(1574 4069)">
                <path id="Exclusion_2" data-name="Exclusion 2" d="M15,30A15,15,0,0,1,4.393,4.393,15,15,0,1,1,25.606,25.606,14.9,14.9,0,0,1,15,30ZM8.237,19.494h0a.213.213,0,0,0-.231.152A.335.335,0,0,0,8.2,20a11.209,11.209,0,0,0,4.562,1,8.411,8.411,0,0,0,8.74-8.25c0-.065,0-.2,0-.336a.115.115,0,0,1,0-.02.116.116,0,0,0,0-.02.19.19,0,0,0,0-.029.2.2,0,0,1,0-.031l0-.08c0-.07,0-.131-.005-.167a8.671,8.671,0,0,0,1.461-1.3.193.193,0,0,0,.037-.2.125.125,0,0,0-.115-.072.177.177,0,0,0-.058.01l-.234.083a7.342,7.342,0,0,1-1.319.386,5.459,5.459,0,0,0,1.695-1.658.223.223,0,0,0,.024-.229A.17.17,0,0,0,22.826,9a.231.231,0,0,0-.1.024c-.115.054-.245.117-.383.185a10.015,10.015,0,0,1-1.791.749l-.024.008-.032.01a3.374,3.374,0,0,0-5.746,2.4c0,.03,0,.067,0,.107,0,.1,0,.2,0,.268A6.961,6.961,0,0,1,9.256,9.873a.276.276,0,0,0-.206-.121.213.213,0,0,0-.2.171,6.576,6.576,0,0,0-.118,1.351A3.335,3.335,0,0,0,10.835,14a3.069,3.069,0,0,1-.765.1,2.233,2.233,0,0,1-1-.252.277.277,0,0,0-.124-.029.186.186,0,0,0-.152.064.254.254,0,0,0-.023.223,3.723,3.723,0,0,0,2.928,2.316,1.93,1.93,0,0,1-.848.134l-.185,0h-.124c-.1,0-.461-.024-.687-.052a.717.717,0,0,0-.086-.005c-.076,0-.212.014-.261.1s-.013.19.084.337A3.915,3.915,0,0,0,12.6,18.385a7.6,7.6,0,0,1-4.355,1.11H8.237Z" fill="#fcefc7"/>
              </g>
              <g @click="goTo('linked in')" class="cursor-pointer" id="Component_7_5" data-name="Component 7 – 5" transform="translate(1512 4069)">
                <path id="Exclusion_5" data-name="Exclusion 5" d="M15,30A15,15,0,0,1,4.393,4.393,15,15,0,1,1,25.606,25.606,14.9,14.9,0,0,1,15,30Zm2.959-15c.233,0,.994.094.994,1.3V19.2a1.357,1.357,0,0,0,2.714,0V16.3c0-2.208-.913-3.526-2.442-3.526-2,0-2.4,1.259-2.442,1.4a1.4,1.4,0,1,0-2.805,0V19.2a1.359,1.359,0,0,0,1.357,1.357h.09A1.358,1.358,0,0,0,16.782,19.2V16.3C16.782,15.463,17.2,15,17.959,15Zm-7.694-2.222a1.407,1.407,0,0,0-1.4,1.4v4.973a1.4,1.4,0,0,0,2.8,0V14.18A1.4,1.4,0,0,0,10.265,12.777Zm.045-4.092a1.361,1.361,0,0,0-1.525,1.334,1.324,1.324,0,0,0,1.429,1.333,1.361,1.361,0,0,0,1.525-1.333A1.324,1.324,0,0,0,10.31,8.685Z" fill="#fcefc7"/>
              </g>
            </g>
          </svg>


        </div>
      </div>
    </div>

    <h4 style="color:#fcefc7" class="hidden text-md mb-4 w-max font-light">Ⓒ  2021 <span class="font-semibold">Temmam Light</span> | All Rights Reserved</h4>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods:{
    goTo(link){
    alert(link+' icon pressed')
    }
  }
}
</script>

<style scoped>
.bg-footer{
  background: #3b3b3b;
}
</style>