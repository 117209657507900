<template>
    <div class="w-full bg-bony-50 flex shadow-md rounded-lg mb-6 flex-col p-4">
      <div class="flex items-end justify-between px-2 py-1 border-b border-gray-300 w-full">
        <h1 class="text-bony-900 text-xl font-semibold">{{ title }}</h1>
        <h6 class="text-xs text-gray-400">Added {{ days }} ago</h6>
      </div>
      <p class="my-2 text-bony-800 text-sm">
        {{ text }}
      </p>

      <div class="flex items-center justify-between py-1 my-1 w-full">

        <div class="flex items-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 18.003 25">
            <path id="icons8_marker" d="M14,2a9,9,0,0,0-9,9c0,5.1,5.663,12.628,8.006,15.522a1.278,1.278,0,0,0,1.991,0C17.34,23.629,23,16.1,23,11A9,9,0,0,0,14,2Zm0,12.216A3.215,3.215,0,1,1,17.216,11,3.214,3.214,0,0,1,14,14.216Z" transform="translate(-5 -2)" fill="#b1b1b1"/>
          </svg>
          <span class="text-xs text-gray-400 mx-2">{{ place }}</span>
        </div>

        <span class="cursor-pointer text-bony-800 hover:text-bony-500 text-sm">
          Read More...
        </span>

      </div>
    </div>
</template>

<script>
export default {
  name: "JobCard",
  props:{
    title:String,
    days:String,
    text:String,
    place:String
  }
}
</script>

<style scoped>

</style>