
<script>

import Main from '@/components/Main'
import CardHeader from '@/components/CardHeader'
import SmallCard from '@/components/SmallCard'
import Map from "../../components/Map";
import JobCard from "../../components/JobCard";

export default {
name: "SeekerHome",
  data(){
  return{
    jobs:this.$store.jobs,
    state:''
  }
  },
  components:{
    JobCard,
    Map,
    Main,SmallCard,CardHeader
  },
  methods:{
    setState(v){
      this.state=v!=='any'?v:'';
      this.jobs=this.$store.jobs.filter(i=>i.place.includes(this.state))
    },
  },
  render(){
    return(

        <Main
            // Header Title, Paragraph and Photo
            title="Find your next job now"
            photo={require('@/assets/imgs/s-home.jpg')}
            p="Finally, An Easier Way to Find Work."
            btn="Apply Now"
            btn2="Find a Job"
            btnClick={()=> {
              // this.$router.push('/seeker/apply-now')
              // this.$store.updateCurrentPage()
              open('https://metrojobsonline.securedportals.com/jobportal/jobs')
            }}
            btn2Click={()=> {
              // this.$router.push('/seeker/all-jobs')
              // this.$store.updateCurrentPage()
              open('https://metrojobsonline.securedportals.com/apply')
            }}
        >

            <div class="container w-full mx-auto bg-white rounded-xl shadow-lg pb-6">

            <CardHeader
                padding="10"
                title="Browse our jobs"
            />
              <div class="relative w-11/12 mx-auto rounded-lg shadow-md mt-8 border-4 overflow-hidden border-bony-600">

                <Map click={this.setState}/>

                <div
                    v-show={this.state}
                    style="height:98%;overflow:auto;scrollbar-width:thin"
                    class="md:absolute  w-full md:w-1/2 m-1 rounded-lg bg-white top-0 flex flex-col items-center py-4 col-span-1 px-8 lg:col-span-2 mb-6 ">
                  <div class="flex items-end w-full mb-2">
                    <h1 onClick={()=>this.state=''} class="text-right flex-1 hover:text-bony-600 cursor-pointer">X</h1>
                  </div>
                  {
                    //Rendering All of Jobs' Cards
                    this.jobs.length ?
                        this.jobs.map(job =>
                            <JobCard
                                title={job.title}
                                days={job.days}
                                text={job.text}
                                place={job.place}
                                key={job.days}
                            />)
                        : <div
                            class="w-full bg-red-50 flex items-center shadow-md rounded-lg mb-6 flex-col p-6">

                          <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 125 125">
                            <g id="Group_314" data-name="Group 314" transform="translate(-351.964 -1031.964)">
                              <circle id="Ellipse_8" data-name="Ellipse 8" cx="62.5" cy="62.5" r="62.5"
                                      transform="translate(351.964 1031.964)" fill="#e46464" opacity="0.36"/>
                              <g id="Group_316" data-name="Group 316" transform="translate(383.738 1063.738)">
                                <path id="icons8_box_important_1"
                                      d="M34.159,3.433A30.726,30.726,0,1,0,64.885,34.159,30.725,30.725,0,0,0,34.159,3.433ZM35.7,49.522H32.623a1.537,1.537,0,0,1-1.536-1.536V44.913a1.537,1.537,0,0,1,1.536-1.536H35.7a1.537,1.537,0,0,1,1.536,1.536v3.073A1.537,1.537,0,0,1,35.7,49.522Zm-1.536-12.29h0a3.074,3.074,0,0,1-3.073-3.073V21.869A3.074,3.074,0,0,1,34.159,18.8h0a3.074,3.074,0,0,1,3.073,3.073v12.29A3.074,3.074,0,0,1,34.159,37.232Z"
                                      transform="translate(-3.433 -3.433)" fill="#881b1b"/>
                              </g>
                            </g>
                          </svg>

                          <h1 style="color:#5F1919" class="text-xl text-center font-bold my-4">
                            Oops!
                            <span class="text-sm text-black block font-normal">
                                        We could not found results for your search
                                      </span>
                          </h1>
                        </div>
                  }
                </div>

              </div>


              <CardHeader
                  padding="10"
                  title="The new world of work is already here."
              />

              <p
                  style="width:90%"
                  class="px-8 pt-2 pb-0 text-gray-500"
              >
                Connecting talented people with companies in need of their skills is what we do.
                The top talent of today and tomorrow is already open to new ways to work.
                They embrace it. They thrive in it. Are you? We can help you get there.
              </p>


              <CardHeader
                  padding="10"
                  title="Making an Impact"
              />

              <p
                  style="width:90%"
                  class="px-8 pt-2 pb-0 text-gray-500"
              >
                At MetroJobs, we stand on the side of what’s right.
                As good corporate citizens,
                we operate today in a way that helps cultivate a bright tomorrow.
              </p>

              <p
                  style="width:90%"
                  class="px-8 pt-2 mt-4 pb-0 text-gray-500"
              >
                Our dedication to sustainability is part of our company DNA,
                and it’s outlined in a structured and well-documented Corporate
                Sustainability Strategy with transparency and accountability to
                our environmental, social, and governance practices.

              </p>

              <p
                  style="width:90%"
                  class="px-8 pt-2 my-4 pb-0 text-gray-500"
              >
                We’ve always had a strong sense of purpose and responsibility.
                That’s why we’re dedicated to doing our part to make our world a better place.

              </p>


            </div>
        </Main>

    )
  }
}
</script>

<style scoped>

</style>