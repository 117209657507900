<template>
  <nav class="w-full bg-white">
    <div class="container px-8 lg:px-32 py-8 h-12 flex justify-between items-center lg:items-stretch mx-auto">
      <div class="flex items-center w-full justify-between">
        <div class="mr-10 pl-4 flex flex-1 items-center">
          <img class="w-32 cursor-pointer" @click="routerHandler({to:'/seeker/home'})" :src="logo" alt="logo"/>
          <h3 v-if="name" class="text-base text-gray-800 font-bold tracking-normal leading-tight ml-3 hidden lg:block">
            {{ name }}</h3>
        </div>
        <ul class="hidden xl:flex flex-2 items-start h-full justify-end mb-8 self-center">

          <li v-for="link in links" :key="link.to" @click="routerHandler(link)"
              :class="{'px-10':$store.currentPage.includes(link.name.toLowerCase()),'bg-bony-400 text-bony-900 font-semibold':$store.currentPage.includes(link.name.toLowerCase())}"
              class="cursor-pointer h-10 px-4 rounded-lg flex items-center text-sm tracking-normal text-gray-400">
            <h1 v-if="$store.currentPage.includes(link.name.toLowerCase())">
              {{ link.name }}
            </h1>

            <h1 class="px-0" v-else>
              {{ link.temp }}
            </h1>
          </li>

          <img v-if="profilePhoto" class=" cursor-pointer w-7 h-7 rounded-full mx-2 mt-1" :src="profilePhoto"
               alt="profile"/>

          <svg @mouseout="iconColorHandler" @mouseover="iconColorHandler" class="cursor-pointer my-2 mx-3" id="icons8_male_user" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 30 30">
            <path id="icons8_male_user-2" data-name="icons8_male_user" d="M17,2A15,15,0,1,0,32,17,15,15,0,0,0,17,2Zm0,4.125A4.875,4.875,0,1,1,12.125,11,4.875,4.875,0,0,1,17,6.125ZM17,29a11.967,11.967,0,0,1-9.132-4.218,1.523,1.523,0,0,1,.26-2.207C10.4,20.864,14.549,20,17,20s6.6.864,8.874,2.575a1.523,1.523,0,0,1,.26,2.207A11.973,11.973,0,0,1,17,29Z" transform="translate(-2 -2)" :fill="iconColor"/>
          </svg>


        </ul>
      </div>


<!--      <div class="visible xl:hidden flex items-center">-->

<!--        <ul class="p-2 pb-4 border-r bg-white absolute top-0 left-0 right-0 shadow mt-16 md:mt-16 hidden">-->
<!--          <li v-for="link in links" :key="link.to" @click="routerHandler(link)"-->
<!--              class="flex xl:hidden justify-center cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-bony-800 focus:text-bony-800 focus:outline-none">-->
<!--            <h2 :class="{'text-bony-800':$store.currentPage.includes(link.to)}" class="ml-2 font-bold">{{ link.name }}</h2>-->
<!--          </li>-->
<!--          <li class="flex xl:hidden items-center justify-start px-4 pt-6 mt-4 border-t border-gray-300 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 focus:text-indigo-700 focus:outline-none">-->
<!--            <img v-if="profilePhoto" class="w-7 h-7 rounded-full mx-2" :src="profilePhoto" alt="profile"/>-->

<!--            <svg class="mx-2" v-else width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--                 xmlns="http://www.w3.org/2000/svg">-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                    d="M16 9C16 10.0609 15.5786 11.0783 14.8284 11.8284C14.0783 12.5786 13.0609 13 12 13C10.9391 13 9.92172 12.5786 9.17157 11.8284C8.42143 11.0783 8 10.0609 8 9C8 7.93913 8.42143 6.92172 9.17157 6.17157C9.92172 5.42143 10.9391 5 12 5C13.0609 5 14.0783 5.42143 14.8284 6.17157C15.5786 6.92172 16 7.93913 16 9V9ZM14 9C14 9.53043 13.7893 10.0391 13.4142 10.4142C13.0391 10.7893 12.5304 11 12 11C11.4696 11 10.9609 10.7893 10.5858 10.4142C10.2107 10.0391 10 9.53043 10 9C10 8.46957 10.2107 7.96086 10.5858 7.58579C10.9609 7.21071 11.4696 7 12 7C12.5304 7 13.0391 7.21071 13.4142 7.58579C13.7893 7.96086 14 8.46957 14 9V9Z"-->
<!--                    fill="black"/>-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                    d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM3 12C3 14.09 3.713 16.014 4.908 17.542C5.74723 16.4399 6.8299 15.5467 8.07143 14.9323C9.31297 14.3179 10.6797 13.9988 12.065 14C13.4323 13.9987 14.7819 14.3095 16.0109 14.9088C17.2399 15.508 18.316 16.3799 19.157 17.458C20.0234 16.3216 20.6068 14.9952 20.8589 13.5886C21.111 12.182 21.0244 10.7355 20.6065 9.36898C20.1886 8.00243 19.4512 6.75505 18.4555 5.73004C17.4598 4.70503 16.2343 3.93186 14.8804 3.47451C13.5265 3.01716 12.0832 2.88877 10.6699 3.09997C9.25652 3.31117 7.91379 3.85589 6.75277 4.68905C5.59175 5.52222 4.64581 6.61987 3.99323 7.8912C3.34065 9.16252 3.00018 10.571 3 12V12ZM12 21C9.93395 21.0031 7.93027 20.2923 6.328 18.988C6.97293 18.0647 7.83134 17.3109 8.83019 16.7907C9.82905 16.2705 10.9388 15.9992 12.065 16C13.1772 15.9991 14.2735 16.2636 15.2629 16.7714C16.2524 17.2793 17.1064 18.0159 17.754 18.92C16.1393 20.2667 14.1026 21.0029 12 21V21Z"-->
<!--                    fill="black"/>-->
<!--            </svg>-->

<!--            <span>Sam Alex</span>-->
<!--          </li>-->
<!--        </ul>-->


<!--        <svg @click="MenuHandler($event, true)" aria-haspopup="true" aria-label="Main Menu"-->
<!--             xmlns="http://www.w3.org/2000/svg" class="show-m-menu icon icon-tabler icon-tabler-menu" width="28"-->
<!--             height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"-->
<!--             stroke-linejoin="round">-->
<!--          <path stroke="none" d="M0 0h24v24H0z"></path>-->
<!--          <line x1="4" y1="8" x2="20" y2="8"></line>-->
<!--          <line x1="4" y1="16" x2="20" y2="16"></line>-->
<!--        </svg>-->
<!--        <div class="hidden close-m-menu close" @click="MenuHandler($event, false)">-->
<!--          <svg aria-label="Close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"-->
<!--               stroke-width="1.5" stroke="currentColor" fill="none"  stroke-linecap="round" stroke-linejoin="round">-->
<!--            <path stroke="none" d="M0 0h24v24H0z"/>-->
<!--            <line x1="18" y1="6" x2="6" y2="18"/>-->
<!--            <line x1="6" y1="6" x2="18" y2="18"/>-->
<!--          </svg>-->
<!--        </div>-->
<!--      </div>-->


      <li v-for="link in links" :key="link.to" @click="routerHandler(link)"
          :class="{'px-10':$store.currentPage.includes(link.name.toLowerCase()),'hidden bg-bony-400 text-bony-900 font-semibold':$store.currentPage.includes(link.name.toLowerCase())}"
          class="xl:hidden cursor-pointer h-10 px-4 rounded-lg flex items-center text-sm tracking-normal text-gray-400">

        <h1 v-if="$store.currentPage.includes(link.name.toLowerCase())">

        </h1>

        <h1 class="text-xs w-max px-0" v-else>
          {{ link.temp }}
        </h1>
      </li>

    </div>
  </nav>
</template>

<script>
export default {
  name: "LeftAlignedWithIcons",
  props: {
    logo: String,
    name: String,
    links: Array,
    profile: String
  },
  data() {
    return {
      profilePhoto: this.profile,
      iconColor:'#5a7187'
    };
  },

  methods: {
    iconColorHandler(){
      this.iconColor=(this.iconColor==='#5a7187')?'#C99A2E':'#5a7187';
    },
    dropdownHandler(event) {
      let single = event.currentTarget.getElementsByTagName("ul")[0];
      single.classList.toggle("hidden");
    },
    MenuHandler(el, val) {
      let MainList = el.currentTarget.parentElement.getElementsByTagName("ul")[0];
      let closeIcon = el.currentTarget.parentElement.getElementsByClassName("close-m-menu")[0];
      let showIcon = el.currentTarget.parentElement.getElementsByClassName("show-m-menu")[0];
      if (val) {
        MainList.classList.remove("hidden");
        el.currentTarget.classList.add("hidden");
        closeIcon.classList.remove("hidden");
      } else {
        showIcon.classList.remove("hidden");
        MainList.classList.add("hidden");
        el.currentTarget.classList.add("hidden");
      }
    },
    searchHandler(event) {
      let Input = event.currentTarget.parentElement.getElementsByTagName("input")[0];
      Input.classList.toggle("w-0");
      Input.classList.toggle("w-24");
    },
    routerHandler(link){
      this.$router.push(link.to);
      this.$store.currentPage=window.location.pathname;
      document.querySelectorAll('.close').forEach(i=>i.click());
    }
  },
};
</script>


<style scoped lang="scss">
$bony: #a27c1a;
.flex-2 {
  flex: 2
}

.w-18 {
  width: 6rem
}

.bg {
  background: $bony;
}
</style>