<template>
  <div @click="click(value)" class="cursor-pointer flex items-center flex-col bg-gray-100 mx-2 p-4 rounded-lg shadow-md h-40 justify-evenly">
    <div v-html="icon"></div>
    <h1 style="color:#515151" class="text-center font-bold text-lg mt-2">
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
name: "IndustryCard",
  props:{
    title:String,
    icon:String,
    click:Function,
    value:String
  }
}
</script>

<style scoped>

</style>