
<script>

import Main from '@/components/Main'
import IndustryCard from "@/components/IndustryCard";



export default {
name: "Industry",
  components:{
    IndustryCard,
    Main,
  },
  methods:{

    goTo(indusrty){
      window.location.pathname.includes('/employer')?
        this.$router.push('/employer/get-employees#'+indusrty):
          this.$router.push('/seeker/all-jobs#'+indusrty)
          this.$store.updateCurrentPage()
    }
  },
  render(){
    return(

        <Main
            // Header Title, Paragraph and Photo
            title="Industry Information"
            photo={require('../assets/imgs/indusrty.jpg')}
            p="So, what do you do? What are you good at? Now, we don’t know what you just said—in your head or out loud—but, chances are, we have job opportunities for you. Check out your area of expertise below to see the types of jobs we have and how we help you find them."
        >

          <div class="container w-full mx-auto bg-white rounded-xl grid grid-cols-1 gap-6 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  shadow-lg p-6">

            {this.$store.industries.map(i=>
                <IndustryCard click={this.goTo} value={i.value} key={i.name} title={i.label} icon={i.icon}/>
            )}

          </div>
        </Main>

    )
  }
}
</script>

<style scoped>

</style>