<template>
  <div :class="`${padding ? `pt-${padding}` : 'pt-8'}`" class="flex flex-col md:flex-row px-2 text items-center justify-between border-b border-gray-400  mx-auto">
    <h1 :class="{'text-center':search}" class="flex-1 text-s md:text-left font-bold text">{{ title }}</h1>
    <FormulateInput
        v-if="search"
        type="text"
        outer-class="mb-1"
        placeholder="Job title"
        v-model="searchModel"
        :input-class="['search']"
     />
  </div>
</template>

<script>
export default {
  name: "CardHeader",
  props:{
    title:String,
    padding:String,
    search:Boolean,
    searchBar:Function
  },
  data(){
    return{
      searchModel:''
    }
  },
  watch:{
    searchModel(){
      this.searchBar(this.searchModel)
    }
  }
}
</script>

<style>
.text{
  /*font-size: 30px;*/
  color:#c99a2e;
  width: 95%;
}
.text-s{
  font-size: 28px;
}
.search{
  background: #f3f4f6 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath id='icons8_search' d='M12.5,2a10.5,10.5,0,1,0,6.861,18.422l.639.639V23l8.379,8.379a2.121,2.121,0,0,0,3-3L23,20h-1.94l-.639-.639A10.478,10.478,0,0,0,12.5,2Zm0,3A7.5,7.5,0,1,1,5,12.5,7.477,7.477,0,0,1,12.5,5Z' transform='translate(-2 -2)' fill='%23a27c1a'/%3E%3C/svg%3E%0A") no-repeat right .75rem center/18px 16px !important;
  appearance: none;
  padding: 8px 0.660rem;
}
</style>