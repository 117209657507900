
<script>

import Main from '@/components/Main'
import CardHeader from '@/components/CardHeader'
import SmallCard from '@/components/SmallCard'
import SmallCardNumber from "../../components/SmallCardNumber";

export default {
name: "HowToApply",
  components:{
    SmallCardNumber,
    Main,SmallCard,CardHeader
  },
  methods:{

  },
  render(){
    return(

        <Main
            // Header Title, Paragraph and Photo
            title="Here's how to apply"
            photo={require('@/assets/imgs/how.jpg')}
            p="Answer a few questions, click a few buttons and pretty soon you'll be applying for jobs all over this place"
        >

            <div class="container w-full mx-auto bg-white rounded-xl shadow-lg pb-6">

            <CardHeader
                padding="10"
                title="It's really, really easy to apply for jobs. Really."
            />

            <p
                style="width:90%"
                class="px-8 pt-2 pb-0 text-gray-500"
            >
              You can fill out a short application, send it to us and let us find you a job.
              Or you can search for a specific job and apply for it yourself.
              <br/>
              <br/>
              You pick. It's honestly that simple.
            </p>


              <CardHeader
                  padding="10"
                  title="What happens after you apply?"
              />

              <p
                  style="width:90%"
                  class="px-8 pt-2 pb-0 text-gray-500"
              >
                Now, imagine you've sent us your application or applied for a job.
                Are you imagining it? Good. This is basically what we do next:
              </p>


              <div class="flex flex-col lg:flex-row xl:flex-row items-center justify-around px-2 mb-8">

                <SmallCardNumber
                    // Small Card Number with number and text
                    number="1"
                    text="We review your application"
                />

                <SmallCardNumber
                    // Small Card Number with number and text
                    number="2"
                    text="You meet your recruiter"
                />

                <SmallCardNumber
                    // Small Card Number with number and text
                    number="3"
                    text="You start your new job"
                />

              </div>


            </div>
        </Main>

    )
  }
}
</script>

<style scoped>

</style>